import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import grapesjs from 'grapesjs';
import cheerio from 'cheerio';
import { useTranslation } from 'react-i18next';
import { each, isNil, noop } from 'lodash';
// Import Services
import { createBlocks, createCommands, getStylesModule, createTypes, addFont, getInlineHtml, opt } from './service';
// Import SCSS
import 'assets/scss/blockEditor.scss';
// Import Services
import { generatePartialHtml } from 'services/template';

const cmpId = 'html-template-builder';
const isHaveUndoFunctional = true;

const BlockEditor = ({
    dynamicVariables,
    onChange,
    defaultHTML,
    getAssets,
    addAsset,
    isPartialHTML,
    dynamicVariableChannelKey,
    onFocus,
}) => {
    const { t } = useTranslation();

    const editor = useRef();
    const canvasRef = useRef();

    const cmpSelectors = useMemo(
        () => ({
            canvas: `${cmpId}-canvas`,
            devices: `${cmpId}-devices`,
            options: `${cmpId}-options`,
            commands: `${cmpId}-commands`,
            views: `${cmpId}-views`,
            viewsContainer: `${cmpId}-views-container`,
        }),
        [],
    );

    useEffect(() => {
        const editorInstance = grapesjs.init({
            container: `#${cmpSelectors.canvas}`,
            fromElement: true,
            noticeOnUnload: 0,
            // blockManager: {
            //     appendTo: `#${cmpSelectors.viewsContainer}`,
            // },
            // selectorManager: {
            //     appendTo: `#${cmpSelectors.viewsContainer}`,
            // },
            // styleManager: {
            //     appendTo: `#${cmpSelectors.viewsContainer}`,
            // },
            storageManager: {
                autosave: true, // Store data automatically
                autoload: false, // Autoload stored data on init
            },
            exportWrapper: true,
            wrapperIsBody: true,
            baseCss: opt.baseCss,
            panels: {
                defaults: [
                    {
                        id: 'devices',
                        el: `#${cmpSelectors.devices}`,
                        buttons: [
                            {
                                id: 'desktop',
                                command: {
                                    run(e) {
                                        e.setDevice('Desktop');
                                    },
                                    stop() {},
                                },
                                label: `<div><i class="fa fa-desktop "></i><span class="dtm-text"> Desktop</span></div>`,
                                className: 'btn-toggle-borders',
                                togglable: false,
                                active: 1,
                            },
                            {
                                id: 'tablet',
                                command: {
                                    run(e) {
                                        e.setDevice('Tablet');
                                    },
                                    stop() {},
                                },
                                label: `<div><i class="fa fa-tablet"></i><span class="dtm-text"> Tablet</span></div>`,
                                className: 'btn-toggle-borders',
                                togglable: false,
                            },
                            {
                                id: 'mobile',
                                command: {
                                    run(e) {
                                        e.setDevice('Mobile portrait');
                                    },
                                    stop() {},
                                },
                                label: '<div><i class="fa fa-mobile"></i><span class="dtm-text"> Mobile</span></div>',
                                className: 'btn-toggle-borders',
                                togglable: false,
                            },
                        ],
                    },

                    {
                        id: 'views',
                        el: `#${cmpSelectors.views}`,
                        buttons: [
                            {
                                id: 'show-blocks',
                                active: false,
                                className: 'tab-btn',
                                label: "<div><i class='icon bc-icon-blocks'></i><span> Blocks</span></div>",
                                command: 'core:open-blocks',
                                togglable: false,
                            },
                            {
                                id: 'show-layers',
                                active: false,
                                className: 'tab-btn',
                                label: "<div><i class='icon bc-icon-skins'></i><span> Layers</span></div>",
                                command: 'core:open-layers',
                                togglable: false,
                            },
                            {
                                id: 'show-styles',
                                active: false,
                                className: 'tab-btn',
                                label: "<div><i class='icon bc-icon-brush'></i><span> Styles</span></div>",
                                command: 'core:open-styles',
                                togglable: false,
                            },
                            {
                                id: 'open-tm',
                                active: false,
                                className: 'tab-btn',
                                label: "<div><i class='icon bc-icon-settings'></i><span> Settings</span></div>",
                                command: 'open-tm',
                                togglable: false,
                            },
                        ],
                    },
                    {
                        id: 'views-container',
                        el: `#${cmpSelectors.viewsContainer}`,
                    },
                    {
                        id: 'options',
                        el: `#${cmpSelectors.options}`,

                        buttons: [
                            // {
                            //     id: 'undo',
                            //     command: 'undo',
                            //     label: `<div><i class='icon bc-icon-undo'></i><span> Undo</span></div>`,
                            //     togglable: false,
                            // },
                            // {
                            //     id: 'redo',
                            //     command: 'redo',
                            //     label: "<div><i class='icon bc-icon-redo'></i><span> Redo</span></div>",
                            //     togglable: false,
                            // },
                            {
                                id: 'gallery',
                                command: 'core:open-assets',
                                label: "<div><i class='icon bc-icon-Image'></i><span> Gallery</span></div>",
                                togglable: false,
                            },
                            {
                                id: 'component-outline',
                                command: opt.componentOutline,
                                active: true,
                                label: "<div class='show-item'><span>Show outline</span></div>",
                                togglable: true,
                            },
                        ],
                    },
                ],
            },
            assetManager: {
                assets: getAssets(),
                uploadFile: (e) => {
                    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
                    addAsset(files, (items) => {
                        items.forEach((item) => {
                            editorInstance.AssetManager.add(item);
                        });
                    });
                },
                uploadText: 'uploadText',
                // handleAdd: (textFromInput) => {
                //     editorInstance.AssetManager.add(textFromInput);
                // },
            },
        });

        const blockManager = editorInstance.BlockManager;
        const editorCommands = editorInstance.Commands;
        const editorPanels = editorInstance.Panels;

        const sectors = editorInstance.StyleManager.getSectors();

        // Define blocks
        createBlocks(blockManager);
        // Define commands
        createCommands(editorCommands);
        // Define types
        createTypes(editorInstance);

        if (isHaveUndoFunctional === false) {
            editorPanels.removeButton('options', 'undo');
            editorPanels.removeButton('options', 'redo');
        }

        editorInstance.on('load', function () {
            const iframe = canvasRef.current.querySelector('iframe');
            iframe.contentWindow.onfocus = onFocus;
            editorInstance.Panels.getButton('views', 'show-blocks').set('active', true); // tmp solution

            let stylesModuleData = getStylesModule();
            sectors.reset();
            sectors.add(stylesModuleData);

            editorInstance.getWrapper().set('stylable', true); // TODO false

            addFont(editorInstance);
            editorInstance.runCommand(opt.componentOutline);

            const cheerioLoad = cheerio.load(defaultHTML);
            const defaultBodyStyle = cheerioLoad('body').css();

            editorInstance.CssComposer.clear();
            editorInstance.CssComposer.setRule('body', defaultBodyStyle);
            editorInstance.setComponents(defaultHTML);
            editorInstance.store();

            editor.current = editorInstance;
            editor.current.validationFlagRiser = false;
        });

        editorInstance.on('stop:core:open-assets', () => {
            editorInstance.Panels.getButton('options', 'gallery').set('active', false); // tmp solution
        });

        editorInstance.on('update', () => {
            if (!isNil(editor?.current) && editor.current.validationFlagRiser) {
                onChange(
                    isPartialHTML ? generatePartialHtml(getInlineHtml(editorInstance)) : getInlineHtml(editorInstance),
                );
            }
            editor.current.validationFlagRiser = true;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isNil(editor?.current) && defaultHTML === '') {
            editor.current.validationFlagRiser = false;
            // TODO need change
            editor.current.Components.clear();
            editor.current.CssComposer.clear();
            editor.current.DomComponents.getWrapper().setStyle('');
            editor.current.Panels.getButton('views', 'show-blocks').set('active', true);
            editor.current.runCommand(opt.componentOutline);
        }
    }, [defaultHTML]);

    useEffect(() => {
        if (isNil(editor.current)) return;

        each(dynamicVariables, (item) => {
            const displayNameKey = item.displayNameKey;
            const fieldName = item.channels[dynamicVariableChannelKey].FieldName;
            editor.current.RichTextEditor.add(displayNameKey, {
                icon: t(displayNameKey),
                attributes: { title: t(displayNameKey) },
                result: (rte, _action) => {
                    rte.insertHTML(`{${fieldName}}`);
                },
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicVariables, editor.current]);

    return (
        <div className="crm-block-editor-wrapper">
            <div className="crm-block-editor">
                <div className="crm-block-editor-header">
                    <div className="input-wr"></div>
                    <div id={cmpSelectors.devices} />
                </div>
                <div className="crm-block-editor-body" tabIndex={-1} onFocus={onFocus}>
                    <div className="crm-block-editor-body-left-cnt">
                        <div className="crm-block-editor-body-left-cnt-header">
                            <div id={cmpSelectors.views} />
                        </div>
                        <div className="crm-block-editor-body-left-cnt-content">
                            <div id={cmpSelectors.viewsContainer} />
                        </div>
                    </div>
                    <div className="crm-block-editor-body-right-cnt">
                        <div className="crm-block-editor-body-right-cnt-header">
                            <div id={cmpSelectors.commands} />
                            <div id={cmpSelectors.options} />
                        </div>
                        <div className="crm-block-editor-body-right-cnt-content">
                            <div id={cmpSelectors.canvas} ref={canvasRef} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

BlockEditor.propTypes = {
    dynamicVariables: PropTypes.array,
    onChange: PropTypes.func,
    defaultHTML: PropTypes.string,
    getAssets: PropTypes.func.isRequired,
    addAsset: PropTypes.func.isRequired,
    isPartialHTML: PropTypes.bool,
    dynamicVariableChannelKey: PropTypes.string.isRequired,
    onFocus: PropTypes.func,
};

BlockEditor.defaultProps = {
    dynamicVariables: [],
    onChange: noop,
    defaultHTML: '',
};

export default BlockEditor;
