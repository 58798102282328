import administrationLabels from './administrationLabels';

const labels = {
    MarkAsImportant: 'MarkAsImportant',
    FilterListContainsTheSameFilter: 'FilterListContainsTheSameFilter',
    FillAllFormFields: 'FillAllFormFields',
    FromMustBeSmaller: 'FromMustBeSmaller',
    TooltipUploadedClients: 'TooltipUploadedClients',
    TopOfUploadedClients: 'TopOfUploadedClients', // with params {{uploadedClientsDataCount}} / {{totalClientsDataCount}}
    Offset: 'Offset',
    OneTime: 'OneTime',
    SavePushNotificationTemplate: 'SavePushNotificationTemplate',
    SavePopUpNotificationTemplate: 'SavePopUpNotificationTemplate',
    SaveTelegramTemplate: 'SaveTelegramTemplate',
    SaveInternalMessageTemplate: 'SaveInternalMessageTemplate',
    From: 'From',
    Operator: 'Operator',
    To: 'To',
    DayOfMonth: 'DayOfMonth',
    File: 'File',
    OptionValue: 'OptionValue',
    TopResultsByCount: 'TopResultsByCount',
    Weekday: 'Weekday',
    ScheduleType: 'ScheduleType',
    Yes: 'Yes',
    No: 'No',
    Cancel: 'Cancel',
    RightValue: 'RightValue',
    Archive: 'Archive',
    Convert: 'Convert',
    ReportName: 'ReportName',
    Upload: 'Upload',
    ValidityPeriod: 'ValidityPeriod',
    PopUpNotificationTemplate: 'PopUpNotificationTemplate',
    CreatePushNotificationTemplate: 'CreatePushNotificationTemplate',
    CreatePopUpNotificationTemplate: 'CreatePopUpNotificationTemplate',
    CreateDynamicPopUpTemplate: 'CreateDynamicPopUpTemplate',
    ClonePushNotificationTemplate: 'ClonePushNotificationTemplate',
    ClonePopUpNotificationTemplate: 'ClonePopUpNotificationTemplate',
    CloneDynamicPopUpTemplate: 'CloneDynamicPopUpTemplate',
    EditPushNotificationTemplate: 'EditPushNotificationTemplate',
    EditPopUpNotificationTemplate: 'EditPopUpNotificationTemplate',
    EditDynamicPopUpTemplate: 'EditDynamicPopUpTemplate',
    PredefinedPushNotificationTemplate: 'PredefinedPushNotificationTemplate',
    PredefinedPopUpNotificationTemplate: 'PredefinedPopUpNotificationTemplate',
    PredefinedDynamicPopUpTemplate: 'PredefinedDynamicPopUpTemplate',
    SegmentSuccessfullyConvertedToReport: 'SegmentSuccessfullyConvertedToReport',
    TitleOfPushNotification: 'TitleOfPushNotification',
    EndDateMustBeBiggerThanStartDate: 'EndDateMustBeBiggerThanStartDate',
    StartDateMustBeSmallerThanEndDate: 'StartDateMustBeSmallerThanEndDate',
    StartDateMustBeBiggerThanCurrentDate: 'StartDateMustBeBiggerThanCurrentDate',
    Reset: 'Reset',
    Save: 'Save',
    Connect: 'Connect',
    Reconnect: 'Reconnect',
    Disconnect: 'Disconnect',
    Sport: 'Sport',
    Region: 'Region',
    Days: 'Days',
    BonusIsNotExist: 'BonusIsNotExist',
    CompositeSegmentWasSuccessfullySaved: 'CompositeSegmentWasSuccessfullySaved',
    StaticSegmentWasSuccessfullySaved: 'StaticSegmentWasSuccessfullySaved',
    InDaysAtThisMoment: 'InDaysAtThisMoment',
    DaysAgoAtThisMoment: 'DaysAgoAtThisMoment',
    SaveEmailTemplate: 'SaveEmailTemplate',
    SaveNonCustomerSegment: 'SaveNonCustomerSegment',
    SaveSMSTemplete: 'SaveSMSTemplete',
    SaveHooryTemplate: 'SaveHooryTemplate',
    SaveFastexNotificationTemplate: 'SaveFastexNotificationTemplate',
    SaveInternalMessageTemplete: 'SaveInternalMessageTemplete',
    SaveTelegramTemplete: 'SaveTelegramTemplete',
    SaveSMSTemplate: 'SaveSMSTemplate',
    SaveEmailTemplete: 'SaveEmailTemplete',
    SaveDynamicPopUpTemplate: 'SaveDynamicPopUpTemplate',
    CreateNonCustomerSegment: 'CreateNonCustomerSegment',
    FromDate: 'FromDate',
    MustBeSmallerThan: 'MustBeSmallerThan',
    ToDate: 'ToDate',
    InDays: 'InDays',
    DaysAgo: 'DaysAgo',
    DayOrDays: 'DayOrDays',
    TemplateIsNotUsed: 'TemplateIsNotUsed',
    UnionTooltipText: 'UnionTooltipText',
    IntersectTooltipText: 'IntersectTooltipText',
    DifferenceTooltipText: 'DifferenceTooltipText',
    MergeResultIs: 'MergeResultIs',
    Competition: 'Competition',
    TypeYourText: 'TypeYourText',
    Schedule: 'Schedule',
    Details: 'Details',
    GeneralDetails: 'GeneralDetails',
    General: 'General',
    EmailIsNotInCorrectFormat: 'EmailIsNotInCorrectFormat',
    MustContainsOnlyNumber: 'MustContainsOnlyNumber',
    AmountIsNotValid: 'AmountIsNotValid',
    NoDataFound: 'NoDataFound',
    ProviderType: 'ProviderType',
    ProviderName: 'ProviderName',
    ClientField: 'ClientField',
    TooltipClientField: 'TooltipClientField',
    UploadFile: 'UploadFile',
    IgnoreInvalids: 'IgnoreInvalids',
    IncludeDuplicates: 'IncludeDuplicates',
    PleaseAddFirstSegment: 'PleaseAddFirstSegment',
    SaveCompositeSegment: 'SaveCompositeSegment',
    SegmentContainerMustContainMinimumTwoSegments: 'SegmentContainerMustContainMinimumTwoSegments',
    PleaseAddSegment: 'PleaseAddSegment',
    TooltipCheckboxes: 'TooltipCheckboxes',
    TooltipStaticSegmentUploadFile: 'TooltipStaticSegmentUploadFile',
    TooltipIncludeDuplicates: 'TooltipIncludeDuplicates',
    EditDynamicSegment: 'EditDynamicSegment',
    DynamicSegmentNotContainBasicFiltersWarningMessage: 'DynamicSegmentNotContainBasicFiltersWarningMessage',
    DynamicSegmentNotContainAggregatedFiltersWarningMessage: 'DynamicSegmentNotContainAggregatedFiltersWarningMessage',
    NotContainBasicFilters: 'NotContainBasicFilters',
    NotContainAggregatedFilters: 'NotContainAggregatedFilters',
    FiltersContainer: 'FiltersContainer',
    DynamicSegmentFiltersContainerTooltipText: 'DynamicSegmentFiltersContainerTooltipText',
    SaveAsDraft: 'SaveAsDraft',
    Create: 'Create',
    Count: 'Count',
    ChooseSegment: 'ChooseSegment',
    CreateFromPredefinedSegment: 'CreateFromPredefinedSegment',
    Or: 'Or',
    ChooseTemplate: 'ChooseTemplate',
    CreateFromPredefinedTemplate: 'CreateFromPredefinedTemplate',
    CreateFromPredefinedCustomerJourney: 'CreateFromPredefinedCustomerJourney',
    PredefinedSegments: 'PredefinedSegments',
    PredefinedTemplates: 'PredefinedTemplates',
    PredefinedCustomerJourneys: 'PredefinedCustomerJourneys',
    Total: 'Total',
    DoYouWantToDeleteThisFilterItemFromDynamicSegmentFilterContainer:
        'DoYouWantToDeleteThisFilterItemFromDynamicSegmentFilterContainer',
    DeleteFilterFromDynamicSegmentFilterContainer: 'DeleteFilterFromDynamicSegmentFilterContainer',
    NoSegmentToDisplay: 'NoSegmentToDisplay',
    YourSegmentsWillAppearInThisContainerAsSoonAsYouSelectFilters:
        'YourSegmentsWillAppearInThisContainerAsSoonAsYouSelectFilters',
    DoYouWantToDeleteThisFilterGroupFromDynamicSegmentFilterContainer:
        'DoYouWantToDeleteThisFilterGroupFromDynamicSegmentFilterContainer',
    DoYouWantToDeleteThisFilterSchemaFromDynamicSegmentFilterContainer:
        'DoYouWantToDeleteThisFilterSchemaFromDynamicSegmentFilterContainer',
    DeleteFilterGroupFromDynamicSegmentFilterContainer: 'DeleteFilterGroupFromDynamicSegmentFilterContainer',
    DeleteFilterSchemaFromDynamicSegmentFilterContainer: 'DeleteFilterSchemaFromDynamicSegmentFilterContainer',
    DeleteGoalCampaign: 'DeleteGoalCampaign',
    Untitled: 'Untitled',
    SegmentCreation: 'SegmentCreation',
    DayOfYear: 'DayOfYear',
    CustomValues: 'CustomValues',
    TypeDescription: 'TypeDescription',
    ConsiderTime: 'ConsiderTime',
    ResultIn: 'ResultIn',
    ResultInTooltip: 'ResultInTooltip',
    NoResult: 'NoResult',
    SelectAFilterAndStartBuildingASegment: 'SelectAFilterAndStartBuildingASegment',
    SelectAFilter: 'SelectAFilter',
    Error: 'Error',
    Export: 'Export',
    Date: 'Date',
    DynamicDate: 'DynamicDate',
    StaticDate: 'StaticDate',
    Match: 'Match',
    TypeHere: 'TypeHere',
    Day: 'Day',
    Hour: 'Hour',
    SelectedItems: 'SelectedItems',
    Country: 'Country',
    Amount: 'Amount',
    Currency: 'Currency',
    Month: 'Month',
    StaticDay: 'StaticDay',
    DynamicDay: 'DynamicDay',
    Settings: 'Settings',
    Language: 'Language',
    LogOut: 'LogOut',
    ChooseLanguage: 'ChooseLanguage',
    AreYouSureYouWantToArchiveSegment: 'AreYouSureYouWantToArchiveSegment',
    AreYouSureYouWantToArchiveCampign: 'AreYouSureYouWantToArchiveCampign',
    AreYouSureYouWantToStopCampign: 'AreYouSureYouWantToStopCampign',
    AreYouSureYouWantToDeleteGoalCampaign: 'AreYouSureYouWantToDeleteGoalCampaign',
    DeleteSegment: 'DeleteSegment',
    DeleteTemplate: 'DeleteTemplate',
    DeleteCampaign: 'DeleteCampaign',
    DeleteSelectedSegments: 'DeleteSelectedSegments',
    DeleteSelectedCampaigns: 'DeleteSelectedCampaigns',
    DeleteSelectedTemplates: 'DeleteSelectedTemplates',
    DeleteSelectedSegmentsQuestion: 'DeleteSelectedSegmentsQuestion',
    DeleteSelectedCampaignsQuestion: 'DeleteSelectedCampaignsQuestion',
    DeleteSelectedTemplatesQuestion: 'DeleteSelectedTemplatesQuestion',
    ArchiveSelectedTemplatesQuestion: 'ArchiveSelectedTemplatesQuestion',
    ArchiveSelectedTemplates: 'ArchiveSelectedTemplates',
    AreYouSureYouWantToDeleteSegment: 'AreYouSureYouWantToDeleteSegment',
    AreYouSureYouWantToDeleteTemplate: 'AreYouSureYouWantToDeleteTemplate',
    AreYouSureYouWantToDeleteCampaign: 'AreYouSureYouWantToDeleteCampaign',
    ConvertToReport: 'ConvertToReport',
    ArchiveSegment: 'ArchiveSegment',
    ArchiveCampaign: 'ArchiveCampaign',
    ArchiveTemplate: 'ArchiveTemplate',
    Stop: 'Stop',
    StopCampaign: 'StopCampaign',
    ArchiveSelectedSegments: 'ArchiveSelectedSegments',
    ArchiveSelectedCampaigns: 'ArchiveSelectedCampaigns',
    ArchiveSelectedSegmentsQuestion: 'ArchiveSelectedSegmentsQuestion',
    ArchiveSelectedCampaignsQuestion: 'ArchiveSelectedCampaignsQuestion',
    AreYouSureYouWantToArchiveTemplate: 'AreYouSureYouWantToArchiveTemplate',
    UnarchiveSegment: 'UnarchiveSegment',
    UnarchiveCampaign: 'UnarchiveCampaign',
    UnarchiveSelectedCampaigns: 'UnarchiveSelectedCampaigns',
    UnarchiveSelectedCampaignsQuestion: 'UnarchiveSelectedCampaignsQuestion',
    UnarchiveSelectedSegments: 'UnarchiveSelectedSegments',
    AreYouSureYouWantToUnarchiveSegment: 'AreYouSureYouWantToUnarchiveSegment',
    AreYouSureYouWantToUnarchiveCampaign: 'AreYouSureYouWantToUnarchiveCampaign',
    UnArchiveTemplate: 'UnArchiveTemplate',
    AreYouSureYouWantToUnArchiveTemplate: 'AreYouSureYouWantToUnArchiveTemplate',
    UnArchiveSelectedTemplates: 'UnArchiveSelectedTemplates',
    UnarchiveSelectedTemplatesQuestion: 'UnarchiveSelectedTemplatesQuestion',
    PleaseChooseFile: 'PleaseChooseFile',
    Description: 'Description',
    MustContainMinimumCharacters: 'MustContainMinimumCharacters',
    MustContainMaximumCharacters: 'MustContainMaximumCharacters',
    PleaseAddFilters: 'PleaseAddFilters',
    Type: 'Type',
    NumberOfCustomers: 'NumberOfCustomers',
    GeneralInfo: 'GeneralInfo',
    Close: 'Close',
    SegmentInfo: 'SegmentInfo',
    SaveStaticSegment: 'SaveStaticSegment',
    PleaseUploadFile: 'PleaseUploadFile',
    First200Clients: 'First200Clients',
    UsedIn: 'UsedIn',
    SegmentIsNotUsed: 'SegmentIsNotUsed',
    UsageInCampaigns: 'UsageInCampaigns',
    Promotions: 'Promotions',
    Selected: 'Selected',
    NotSelected: 'NotSelected',
    Preview: 'Preview',
    First100Clients: 'First100Clients',
    ClientsCount: 'ClientsCount',
    Password: 'Password',
    NewPassword: 'NewPassword',
    ConfirmPassword: 'ConfirmPassword',
    PasswordValidation: 'PasswordValidation',
    PasswordsMustMatch: 'PasswordsMustMatch',
    ChangePassword: 'ChangePassword',
    SignIn: 'SignIn',
    ResetPassword: 'ResetPassword',
    ConfirmResetPassword: 'ConfirmResetPassword',
    ForgotPassword: 'ForgotPassword',
    WeWillSendYouResetInstructions: 'WeWillSendYouResetInstructions',
    ResetPasswordMessageIsSentToYourEmail: 'ResetPasswordMessageIsSentToYourEmail',
    YourPasswordWasChangedSuccessfully: 'YourPasswordWasChangedSuccessfully',
    GoToLogInPage: 'GoToLogInPage',
    Welcome: 'Welcome',
    EasyWayToManageYourMarketingEffort: 'EasyWayToManageYourMarketingEffort',
    CRMDescription: 'CRMDescription',
    CRM: 'CRM',
    Success: 'Success',
    Warning: 'Warning',
    Filters: 'Filters',
    Delete: 'Delete',
    CreateDynamicSegment: 'CreateDynamicSegment',
    Apply: 'Apply',
    DynamicSegmentWasSuccessfullySaved: 'DynamicSegmentWasSuccessfullySaved',
    Segment: 'Segment',
    Dashboard: 'Dashboard',
    Template: 'Template',
    Campaign: 'Campaign',
    Journey: 'Journey',
    Report: 'Report',
    Analytics: 'Analytics',
    CreateSegment: 'CreateSegment',
    Dynamic: 'Dynamic',
    Static: 'Static',
    Composite: 'Composite',
    Segments: 'Segments',
    LoadingCompositeSegment: 'LoadingCompositeSegment',
    NoDataToDisplay: 'NoDataToDisplay',
    Title: 'Title',
    Collapse: 'Collapse',
    Expand: 'Expand',
    Browse: 'Browse',
    Search: 'Search',
    UploadedClients: 'UploadedClients',
    SegmentList: 'SegmentList',
    CampaignList: 'CampaignList',
    CampaignReportWithCampaignName: 'CampaignReportWithCampaignName', // with params {{campaignName}}
    CloneDynamicSegment: 'CloneDynamicSegment',
    CreateStaticSegment: 'CreateStaticSegment',
    CreateCompositeSegment: 'CreateCompositeSegment',
    CloneCompositeSegment: 'CloneCompositeSegment',
    EditCompositeSegment: 'EditCompositeSegment',
    MainApp: 'MainApp',
    NotFound: 'NotFound',
    SaveReport: 'SaveReport',
    ReportWasSuccessfullySaved: 'ReportWasSuccessfullySaved',
    CreateSMSTemplate: 'CreateSMSTemplate',
    CreateHooryTemplate: 'CreateHooryTemplate',
    CloneHooryTemplate: 'CloneHooryTemplate',
    EditHooryTemplate: 'EditHooryTemplate',
    PredefinedHooryTemplate: 'PredefinedHooryTemplate',
    CreateFastexNotificationTemplate: 'CreateFastexNotificationTemplate',
    CloneFastexNotificationTemplate: 'CloneFastexNotificationTemplate',
    EditFastexNotificationTemplate: 'EditFastexNotificationTemplate',
    PredefinedFastexNotificationTemplate: 'PredefinedFastexNotificationTemplate',
    CreateEmailTemplate: 'CreateEmailTemplate',
    CreateInternalMessageTemplate: 'CreateInternalMessageTemplate',
    CreateTelegramTemplate: 'CreateTelegramTemplate',
    CreateTemplate: 'CreateTemplate',
    Templates: 'Templates',
    CreateLabel: 'CreateLabel',
    EditLabel: 'EditLabel',
    ReportFields: 'ReportFields',
    SetLabel: 'SetLabel',
    ManageLabels: 'ManageLabels',
    FromDays: 'FromDays',
    ToDays: 'ToDays',
    Name: 'Name',
    Colors: 'Colors',
    DeleteLabel: 'DeleteLabel',
    RemoveLabel: 'RemoveLabel',
    AreYouSureWantToRemoveLabel: 'AreYouSureWantToRemoveLabel', // with params
    AreYouSureWantToDeleteLabel: 'AreYouSureWantToDeleteLabel', // with params
    Default: 'Default',
    TemplateInfo: 'TemplateInfo',
    PredefinedTemplateInfo: 'PredefinedTemplateInfo',
    DeviceType: 'DeviceType',
    Both: 'Both',
    Mobile: 'Mobile',
    Tablet: 'Tablet',
    Desktop: 'Desktop',
    FullScreen: 'FullScreen',
    TestTemplate: 'TestTemplate',
    TestTemplateDescription: 'TestTemplateDescription',
    SendNow: 'SendNow',
    InputClientId: 'InputClientId',
    EmailAddress: 'EmailAddress',
    TelegramChatId: 'TelegramChatId',
    PhoneNumber: 'PhoneNumber',
    SendTo: 'SendTo',
    PredefinedDynamicSegment: 'PredefinedDynamicSegment',
    Labels: 'Labels',
    SetLabels: 'SetLabels',
    QuickView: 'QuickView',
    Channel: 'Channel',
    State: 'State',
    Languages: 'Languages',
    User: 'User',
    CreatedDate: 'CreatedDate',
    ModifiedDate: 'ModifiedDate',
    Label: 'Label',
    LastModified: 'LastModified',
    Used: 'Used',
    NotUsed: 'NotUsed',
    CustomersType: 'CustomersType',
    DynamicVariables: 'DynamicVariables',
    TemplateDynamicVariablesTooltipTitle: 'TemplateDynamicVariablesTooltipTitle',
    TemplateDynamicVariablesTooltipText: 'TemplateDynamicVariablesTooltipText',
    TemplateAddTranslation: 'TemplateAddTranslation',
    SelectLanguage: 'SelectLanguage',
    Subject: 'Subject',
    SymbolsCountIn: 'SymbolsCountIn',
    Bytes: 'Bytes',
    CloneTemplate: 'CloneTemplate',
    CloneSMSTemplate: 'CloneSMSTemplate',
    TemplateList: 'TemplateList',
    SelectTheModeAndLeaveYourComment: 'SelectTheModeAndLeaveYourComment',
    CantChooseTheSameColumnForOneProperty: 'CantChooseTheSameColumnForOneProperty',
    Update: 'Update',
    HeaderIsTheFirstRow: 'HeaderIsTheFirstRow',
    HeaderIsTheFirstRowTooltip: 'HeaderIsTheFirstRowTooltip',
    SelectMode: 'SelectMode',
    Market: 'Market',
    Comment: 'Comment',
    NameFirstCharacterCantBeASymbol: 'NameFirstCharacterCantBeASymbol',
    EditTemplate: 'EditTemplate',
    EditSMSTemplate: 'EditSMSTemplate',
    FirstCharacterCantBeASymbol: 'FirstCharacterCantBeASymbol',
    TemplateWasSuccessfullySaved: 'TemplateWasSuccessfullySaved',
    CloneInternalMessageTemplate: 'CloneInternalMessageTemplate',
    EditInternalMessageTemplate: 'EditInternalMessageTemplate',
    EditTelegramTemplate: 'EditTelegramTemplate',
    CloneTelegramTemplate: 'CloneTelegramTemplate',
    PredefinedSMSTemplate: 'PredefinedSMSTemplate',
    PredefinedInternalMessageTemplate: 'PredefinedInternalMessageTemplate',
    PredefinedTelegramTemplate: 'PredefinedTelegramTemplate',
    CloneEmailTemplate: 'CloneEmailTemplate',
    EditEmailTemplate: 'EditEmailTemplate',
    PredefinedEmailTemplate: 'PredefinedEmailTemplate',
    ResetFilters: 'ResetFilters',
    Provider: 'Provider',
    Status: 'Status',
    LastRunDate: 'LastRunDate',
    AutoStart: 'AutoStart',
    AutoEnd: 'AutoEnd',
    NextRunDate: 'NextRunDate',
    Mode: 'Mode',
    Goal: 'Goal',
    Scheduled: 'Scheduled',
    Idle: 'Idle',
    InProgress: 'InProgress',
    Completed: 'Completed',
    Suspended: 'Suspended',
    Sent: 'Sent',
    Stoped: 'Stoped',
    NonStarted: 'NonStarted',
    Failed: 'Failed',
    Archived: 'Archived',
    Invalid: 'Invalid',
    All: 'All',
    Live: 'Live',
    Test: 'Test',
    Draft: 'Draft',
    Emulate: 'Emulate',
    CreateCampaign: 'CreateCampaign',
    EditCampaign: 'EditCampaign',
    CloneCampaign: 'CloneCampaign',
    CampaignReport: 'CampaignReport',
    CreateCustomerJourney: 'CreateCustomerJourney',
    Campaigns: 'Campaigns',
    PutTheFinalTouchBeforeSaving: 'PutTheFinalTouchBeforeSaving',
    PreSavePopUpDescription: 'PreSavePopUpDescription',
    SaveDynamicSegment: 'SaveDynamicSegment',
    PreSavePopUpGoToMassage: 'PreSavePopUpGoToMassage',
    CampaignInfo: 'CampaignInfo',
    FieldIsRequired: 'FieldIsRequired',
    Operation: 'Operation',
    ColumnsChooser: 'ColumnsChooser',
    TestTempalteDescription: 'TestTempalteDescription',
    CannotBeEmpty: 'CannotBeEmpty',
    InvalidHtmlTags: 'InvalidHtmlTags',
    UpdateDynamicSegment: 'UpdateDynamicSegment',
    ap: 'PersianAZ',
    ar: 'Arabic ',
    az: 'Azerbaijani',
    bg: 'Bulgarian',
    bn: 'Bengali',
    cn: 'CanadianEnglish',
    cs: 'Czech',
    da: 'Danish',
    de: 'German',
    el: 'Greek',
    en: 'English',
    es: 'Spanish',
    et: 'Estonian',
    fa: 'Persian',
    fi: 'Finnish',
    fr: 'French',
    he: 'Hebrew',
    hr: 'Croatian',
    hy: 'Armenian',
    id: 'BahasaIndonesia',
    it: 'Italian',
    ja: 'Japanese',
    ka: 'Georgian',
    kk: 'Kazakh',
    ko: 'Korean',
    ku: 'Kurdish',
    lt: 'Lithuanian',
    lv: 'Latvian',
    mg: 'Malagasy',
    mk: 'Macedonian',
    ms: 'Malay',
    nl: 'Dutch',
    no: 'Norwegian',
    pb: 'BrazilianPortuguese',
    pl: 'Polski',
    pt: 'Português',
    ro: 'Romanian',
    rt: 'RussianTranslit',
    ru: 'Russian ',
    sk: 'Slovak',
    sl: 'Slovene',
    sq: 'Albanian',
    sr: 'Serbian ',
    sv: 'Swedish',
    sw: 'Swahili',
    th: 'Thai',
    tr: 'Turkish ',
    uk: 'Ukrainian',
    us: 'USEnglish',
    uz: 'Uzbek',
    vi: 'Vietnamese',
    zh: 'Chinese',
    zs: 'ChineseSimplified',
    ReportList: 'ReportList',
    Creator: 'Creator',
    LastModifier: 'LastModifier',
    Standart: 'Standart',
    Reports: 'Reports',
    CreateReport: 'CreateReport',
    ReportType: 'ReportType',
    CustomReport: 'CustomReport',
    ScheduleReport: 'ScheduleReport',
    DeleteSelectedReport: 'DeleteSelectedReport',
    DeleteSelectedReportsQuestion: 'DeleteSelectedReportsQuestion',
    DeleteSelectedReports: 'DeleteSelectedReports',
    AreYouSureYouWantToDeleteReport: 'AreYouSureYouWantToDeleteReport',
    EndDateMustBeBiggerThanCurrentDate: 'EndDateMustBeBiggerThanCurrentDate',
    SelectAtLeastOneUnit: 'SelectAtLeastOneUnit',
    SelectAtLeastOneWeek: 'SelectAtLeastOneWeek',
    AreYouSureYouWantToDeleteExecutedReport: 'AreYouSureYouWantToDeleteExecutedReport',
    ReportInfo: 'ReportInfo',
    CompositeReportInfo: 'CompositeReportInfo',
    DeleteReport: 'DeleteReport',
    ExecutedReportsList: 'ExecutedReportsList',
    ExecutedReportsListWithReportName: 'ExecutedReportsListWithReportName', // with params {{reportName}}
    CloneReport: 'CloneReport',
    EditReport: 'EditReport',
    Repeat: 'Repeat',
    StartDate: 'StartDate',
    StartTime: 'StartTime',
    EndDate: 'EndDate',
    StopReportSchedule: 'StopReportSchedule',
    UsageInWorkflows: 'UsageInWorkflows',
    CreateCustomRecurrence: 'CreateCustomRecurrence',
    CreateAMoreFlexibleScheduleForYourReport: 'CreateAMoreFlexibleScheduleForYourReport',
    Time: 'Time',
    ChooseCustomHours: 'ChooseCustomHours',
    ChooseCustomDays: 'ChooseCustomDays',
    ChooseCustomWeekdays: 'ChooseCustomWeekdays',
    ChooseCustomMonths: 'ChooseCustomMonths',
    ChooseCustomYear: 'ChooseCustomYear',
    Done: 'Done',
    FTPRecipients: 'FTPRecipients',
    EmailProviders: 'EmailProviders',
    Every: 'Every',
    years: 'years',
    months: 'months',
    DropHere: 'DropHere',
    DaysLater: 'DaysLater',
    DaysLeft: 'DaysLeft',
    SameDay: 'SameDay',
    LastDayOfMonth: 'LastDayOfMonth',
    Version: 'Version',
    ExecutionTime: 'ExecutionTime',
    ReportsResultGridTextLessThan: 'Lessthan1minute',
    ReportsResultGridTextMinute: 'minute',
    DeleteExecutedReport: 'DeleteExecutedReport',
    Result: 'Result',
    History: 'History',
    SelectAll: 'SelectAll',
    Elements: 'Elements',
    Possible: 'Possible',
    Current: 'Current',
    Now: 'Now',
    Today: 'Today',
    Yesterday: 'Yesterday',
    ThisWeek: 'ThisWeek',
    ThreeDaysAgo: 'ThreeDaysAgo',
    LastWeek: 'LastWeek',
    AWeekAgo: 'AWeekAgo',
    LastMonth: 'LastMonth',
    OneDaysAgo: 'OneDaysAgo',
    SevenDaysAgo: 'SevenDaysAgo',
    ThrtyDaysAgo: 'ThrtyDaysAgo',
    Subtract: 'Subtract',
    Add: 'Add',
    Exact: 'Exact',
    FromAmount: 'FromAmount',
    ToAmount: 'ToAmount',
    Equivalent: 'Equivalent',
    AddFilters: 'AddFilters',
    None: 'None',
    FromMustBeSmallerThanTo: 'FromMustBeSmallerThanTo',
    ToMustBeBiggerThanFrom: 'ToMustBeBiggerThanFrom',
    FromCount: 'FromCount',
    ColumnKPIs: 'ColumnKPIs',
    Ok: 'Ok',
    CampaignSwitchChangeMessage: 'CampaignSwitchChangeMessage',
    CampaignSwitchChangeTitleMessage: 'CampaignSwitchChangeTitleMessage',
    Union: 'Union',
    Intersect: 'Intersect',
    Difference: 'Difference',
    ExportFileFormat: 'ExportFileFormat',
    Hourly: 'Hourly',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Annually: 'Annually',
    ExecutedReport: 'ExecutedReport',
    ShowClientList: 'ShowClientList',
    HideClientList: 'HideClientList',
    Bonus: 'Bonus',
    SegmentDescription: 'SegmentDescription',
    TemplateDescription: 'TemplateDescription',
    BonusDescription: 'BonusDescription',
    Bonuses: 'Bonuses',
    IncludeInternalMessage: 'IncludeInternalMessage',
    BonusBudget: 'BonusBudget',
    BonusCalculation: 'BonusCalculation',
    BonusKind: 'BonusKind',
    BudgetAmount: 'BudgetAmount',
    AllowOverBudget: 'AllowOverBudget',
    BonusCalculateAction: 'BonusCalculateAction',
    Percentage: 'Percentage',
    ConsiderLimits: 'ConsiderLimits',
    TooltipBonusCalculationDynamic: 'TooltipBonusCalculationDynamic',
    TooltipBonusCalculationStatic: 'TooltipBonusCalculationStatic',
    TooltipBonusCalculationBonusKind: 'TooltipBonusCalculationBonusKind',
    TooltipBonusCalculationAction: 'TooltipBonusCalculationAction',
    TooltipBonusCalculationPercentage: 'TooltipBonusCalculationPercentage',
    TooltipBonusCalculationConsiderLimits: 'TooltipBonusCalculationConsiderLimits',
    Object: 'Object',
    KPI: 'KPI',
    FromFile: 'FromFile',
    TooltipBonusCalculationObject: 'TooltipBonusCalculationObject',
    TooltipBonusCalculationKPI: 'TooltipBonusCalculationKPI',
    TooltipBonusCalculationFromFile: 'TooltipBonusCalculationFromFile',
    SaveCampaign: 'SaveCampaign',
    CustomerJourneys: 'CustomerJourneys',
    DeleteSelectedCustomerJourneys: 'DeleteSelectedCustomerJourneys',
    DeleteSelectedCustomerJourneysQuestion: 'DeleteSelectedCustomerJourneysQuestion',
    DeleteCustomerJourney: 'DeleteCustomerJourney',
    AreYouSureYouWantToDeletCustomerJourney: 'AreYouSureYouWantToDeletCustomerJourney',
    ArchiveSelectedCustomerJourneys: 'ArchiveSelectedCustomerJourneys',
    ArchiveSelectedCustomerJourneysQuestion: 'ArchiveSelectedCustomerJourneysQuestion',
    ArchiveCustomerJourney: 'ArchiveCustomerJourney',
    AreYouSureYouWantToArchiveCustomerJourney: 'AreYouSureYouWantToArchiveCustomerJourney',
    UnarchiveSelectedCustomerJourneys: 'UnarchiveSelectedCustomerJourneys',
    UnarchiveSelectedCustomerJourneysQuestion: 'UnarchiveSelectedCustomerJourneysQuestion',
    UnarchiveCustomerJourney: 'UnarchiveCustomerJourney',
    AreYouSureYouWantToUnarchiveCustomerJourney: 'AreYouSureYouWantToUnarchiveCustomerJourney',
    StopCustomerJourney: 'StopCustomerJourney',
    AreYouSureYouWantToStopCustomerJourney: 'AreYouSureYouWantToStopCustomerJourney',
    StartCustomerJourney: 'StartCustomerJourney',
    AreYouSureYouWantToStartCustomerJourney: 'AreYouSureYouWantToStartCustomerJourney',
    NotAllowDeleteSelectedCustomerJourneys: 'NotAllowDeleteSelectedCustomerJourneys',
    AllowDeleteSelectedCustomerJourneys: 'AllowDeleteSelectedCustomerJourneys',
    NotAllowArchiveSelectedCustomerJourneys: 'NotAllowArchiveSelectedCustomerJourneys',
    AllowArchiveSelectedCustomerJourneys: 'AllowArchiveSelectedCustomerJourneys',
    NotAllowUnarchiveSelectedCustomerJourneys: 'NotAllowUnarchiveSelectedCustomerJourneys',
    AllowUnarchiveSelectedCustomerJourneys: 'AllowUnarchiveSelectedCustomerJourneys',
    Start: 'Start',
    Saving: 'Saving',
    Loading: 'Loading',
    MON: 'Mon',
    TUE: 'Tue',
    WED: 'Wed',
    THU: 'Thu',
    FRI: 'Fri',
    SAT: 'Sat',
    SUN: 'Sun',
    JAN: 'Jan',
    FEB: 'Feb',
    MAR: 'Mar',
    APR: 'Apr',
    MAY: 'May',
    JUN: 'Jun',
    JUL: 'Jul',
    AUG: 'Aug',
    SEP: 'Sep',
    OCT: 'Oct',
    NOV: 'Nov',
    DEC: 'Dec',
    RecurrenceCount: 'RecurrenceCount',
    ExecuteReport: 'ExecuteReport',
    DoYouWantToExecuteReport: 'DoYouWantToExecuteReport',
    DoYouWantToStopReportSchedule: 'DoYouWantToStopReportSchedule',
    NotAllowArchiveSelectedCampaigns: 'NotAllowArchiveSelectedCampaigns',
    AllowArchiveSelectedCampaigns: 'AllowArchiveSelectedCampaigns',
    NotAllowDeleteSelectedCampaigns: 'NotAllowDeleteSelectedCampaigns',
    AllowDeleteSelectedCampaigns: 'AllowDeleteSelectedCampaigns',
    NotAllowUnarchiveSelectedCampaigns: 'NotAllowUnarchiveSelectedCampaigns',
    AllowUnarchiveSelectedCampaigns: 'AllowUnarchiveSelectedCampaigns',
    NotAllowDeleteSelectedSegments: 'NotAllowDeleteSelectedSegments',
    AllowDeleteSelectedSegments: 'AllowDeleteSelectedSegments',
    NotAllowDeleteSelectedTemplates: 'NotAllowDeleteSelectedTemplates',
    AllowDeleteSelectedTemplates: 'AllowDeleteSelectedTemplates',
    NotAllowDeleteSelectedReports: 'NotAllowDeleteSelectedReports',
    AllowDeleteSelectedReports: 'AllowDeleteSelectedReports',
    AllowArchiveSelectedSegments: 'AllowArchiveSelectedSegments',
    NotAllowArchiveSelectedSegments: 'NotAllowArchiveSelectedSegments',
    AllowUnarchiveSelectedSegments: 'AllowUnarchiveSelectedSegments',
    NotAllowUnarchiveSelectedSegments: 'NotAllowUnarchiveSelectedSegments',
    AllowArchiveSelectedTemplates: 'AllowArchiveSelectedTemplates',
    NotAllowArchiveSelectedTemplates: 'NotAllowArchiveSelectedTemplates',
    AllowUnarchiveSelectedTemplates: 'AllowUnarchiveSelectedTemplates',
    NotAllowUnarchiveSelectedTemplates: 'NotAllowUnarchiveSelectedTemplates',
    Unarchive: 'Unarchive',
    PleaseChooseTemplate: 'PleaseChooseTemplate',
    PleaseChooseSegment: 'PleaseChooseSegment',
    PleaseChooseBonus: 'PleaseChooseBonus',
    PleaseFillBonusContent: 'PleaseFillBonusContent',
    MustBeGreaterThenZero: 'MustBeGreaterThenZero',
    BonusInfo: 'BonusInfo',
    Amounts: 'Amounts',
    MinAmount: 'MinAmount',
    MaxAmount: 'MaxAmount',
    Info: 'Info',
    CampaignActivity: 'CampaignActivity',
    ExecuteId: 'ExecuteId',
    TrackedLinks: 'TrackedLinks',
    BonusSummaryByCurrency: 'BonusSummaryByCurrency',
    BonusReceivedPlayers: 'BonusReceivedPlayers',
    EmailActivity: 'EmailActivity',
    CountOfSentBonus: 'CountOfSentBonus',
    CampaignKPI: 'CampaignKPI',
    ClientId: 'ClientId',
    ClientEmail: 'ClientEmail',
    TelegramClientId: 'TelegramClientId',
    Email: 'Email',
    FirstName: 'FirstName',
    LastName: 'LastName',
    Login: 'Login',
    MobilePhone: 'MobilePhone',
    WinAmount: 'WinAmount',
    OpenDate: 'OpenDate',
    ClickedDate: 'ClickedDate',
    SendDate: 'SendDate',
    SendCount: 'SendCount',
    OpenCount: 'OpenCount',
    ClickedCount: 'ClickedCount',
    NA: 'NA',
    UnarchiveSelectedSegmentsQuestion: 'UnarchiveSelectedSegmentsQuestion',
    LoadMore: 'LoadMore',
    LiveMonitoring: 'LiveMonitoring',
    Excel: 'Excel',
    AttachedSegment: 'AttachedSegment',
    SegmentType: 'SegmentType',
    AttachedTemplate: 'AttachedTemplate',
    AttachedBonus: 'AttachedBonus',
    BonusesSent: 'BonusesSent',
    GoalProgress: 'GoalProgress',
    OpenData: 'OpenData',
    ClickData: 'ClickData',
    ClientsClickedTrackedLink: 'ClientsClickedTrackedLink',
    CSV: 'CSV',
    CollectAnalysisBy: 'CollectAnalysisBy',
    TooltipCollectAnalysisBy: 'TooltipCollectAnalysisBy',
    Providers: 'Providers',
    Recipients: 'Recipients',
    GoalType: 'GoalType',
    Bet: 'Bet',
    Deposit: 'Deposit',
    Products: 'Products',
    CloneSegment: 'CloneSegment',
    EditSegment: 'EditSegment',
    ChangeCampaignMode: 'ChangeCampaignMode',
    CloneCustomerJourney: 'CloneCustomerJourney',
    EditCustomerJourney: 'EditCustomerJourney',
    ExecutedReports: 'ExecutedReports',
    ReloadData: 'ReloadData',
    TemplateIsUsedInObjects: 'TemplateIsUsedInObjects',
    TemplateIsUsedInWorkflows: 'TemplateIsUsedInWorkflows',
    TemplateIsUsedInCampaigns: 'TemplateIsUsedInCampaigns',
    Continue: 'Continue',
    SaveCustomerJourney: 'SaveCustomerJourney',
    Blocks: 'Blocks',
    Target: 'Target',
    Action: 'Action',
    Trigger: 'Trigger',
    Condition: 'Condition',
    Less: 'Less',
    More: 'More',
    DeleteSelectedBlocks: 'DeleteSelectedBlocks',
    DeleteSelectedBlocksConfirmationText: 'DeleteSelectedBlocksConfirmationText',
    DeleteSelectedBlocksWarningConfirmationText: 'DeleteSelectedBlocksWarningConfirmationText',
    DateRangeThreeMonthWarning: 'DateRangeThreeMonthWarning',
    CustomFields: 'CustomFields',
    NumberOfNonCustomers: 'NumberOfNonCustomers',
    IsUsedInObjectsLabel: 'IsUsedInObjectsLabel',
    CompositeSegments: 'CompositeSegments',
    And: 'And',
    TooltipIgnoreInvalids: 'TooltipIgnoreInvalids',
    IsNonMarketing: 'IsNonMarketing',
    CJBlockBetCalculated: 'CJBlockBetCalculated',
    CJBlockBetCalculatedDescription: 'CJBlockBetCalculatedDescription',
    CJBlockBetCalculatedDescriptionDescription: 'CJBlockBetCalculatedDescriptionDescription',
    CJBlockBonusClaim: 'CJBlockBonusClaim',
    CJBlockBonusClaimDescription: 'CJBlockBonusClaimDescription',
    CJBlockBonusComplete: 'CJBlockBonusComplete',
    CJBlockBonusCompleteDescription: 'CJBlockBonusCompleteDescription',
    CJBlockOptIn: 'CJBlockOptIn',
    CJBlockOptInDescription: 'CJBlockOptInDescription',
    CJBlockCasinoWin: 'CJBlockCasinoWin',
    CJBlockCasinoWinDescription: 'CJBlockCasinoWinDescription',
    CJBlockCasinoBet: 'CJBlockCasinoBet',
    CJBlockCasinoBetDescription: 'CJBlockCasinoBetDescription',
    CJBlockLinkTracker: 'CJBlockLinkTracker',
    CJBlockLinkTrackerDescription: 'CJBlockLinkTrackerDescription',
    CJBlockLose: 'CJBlockLose',
    CJBlockLoseDescription: 'CJBlockLoseDescription',
    CJBlockBet: 'CJBlockBet',
    CJBlockBetDescription: 'CJBlockBetDescription',
    CJBlockRealMoneyBet: 'CJBlockRealMoneyBet',
    CJBlockRealMoneyBetDescription: 'CJBlockRealMoneyBetDescription',
    CJBlockWin: 'CJBlockWin',
    CJBlockWinDescription: 'CJBlockWinDescription',
    CJBlockDeposit: 'CJBlockDeposit',
    CJBlockDepositDescription: 'CJBlockDepositDescription',
    CJBlockEmailTracker: 'CJBlockEmailTracker',
    CJBlockEmailTrackerDescription: 'CJBlockEmailTrackerDescription',
    CJBlockLogin: 'CJBlockLogin',
    CJBlockLoginDescription: 'CJBlockLoginDescription',
    CJBlockVerification: 'CJBlockVerification',
    CJBlockVerificationDescription: 'CJBlockVerificationDescription',
    CJBlockWithdrawal: 'CJBlockWithdrawal',
    CJBlockWithdrawalDescription: 'CJBlockWithdrawalDescription',
    CJBlockRegistration: 'CJBlockRegistration',
    CJBlockRegistrationDescription: 'CJBlockRegistrationDescription',
    CJBlockSegment: 'CJBlockSegment',
    CJBlockSegmentDescription: 'CJBlockSegmentDescription',
    CJBlockClientBet: 'CJBlockClientBet',
    CJBlockClientBetDescription: 'CJBlockClientBetDescription',
    CJBlockClientRealMoneyBet: 'CJBlockClientRealMoneyBet',
    CJBlockClientRealMoneyBetDescription: 'CJBlockClientRealMoneyBetDescription',
    CJBlockClientDeposit: 'CJBlockClientDeposit',
    CJBlockClientDepositDescription: 'CJBlockClientDepositDescription',
    CJBlockClientLogin: 'CJBlockClientLogin',
    CJBlockClientLoginDescription: 'CJBlockClientLoginDescription',
    CJBlockClientOptIn: 'CJBlockClientOptIn',
    CJBlockClientOptInDescription: 'CJBlockClientOptInDescription',
    CJBlockClientVerification: 'CJBlockClientVerification',
    CJBlockClientVerificationDescription: 'CJBlockClientVerificationDescription',
    CJBlockClientWithdrawal: 'CJBlockClientWithdrawal',
    CJBlockClientWithdrawalDescription: 'CJBlockClientWithdrawalDescription',
    CJBlockTelegramSender: 'CJBlockTelegramSender',
    CJBlockTelegramSenderDescription: 'CJBlockTelegramSenderDescription',
    CJBlockAggregator: 'CJBlockAggregator',
    CJBlockAggregatorDescription: 'CJBlockAggregatorDescription',
    CJBlockDynamicBonusSender: 'CJBlockDynamicBonusSender',
    CJBlockDynamicBonusSenderDescription: 'CJBlockDynamicBonusSenderDescription',
    CJBlockBonusSender: 'CJBlockBonusSender',
    CJBlockBonusSenderDescription: 'CJBlockBonusSenderDescription',
    CJBlockInternalMessageSender: 'CJBlockInternalMessageSender',
    CJBlockInternalMessageSenderDescription: 'CJBlockInternalMessageSenderDescription',
    CJBlockSmsSender: 'CJBlockSmsSender',
    CJBlockSmsSenderDescription: 'CJBlockSmsSenderDescription',
    CJBlockTagSetter: 'CJBlockTagSetter',
    CJBlockTagSetterDescription: 'CJBlockTagSetterDescription',
    CJBlockEmailSender: 'CJBlockEmailSender',
    CJBlockEmailSenderDescription: 'CJBlockEmailSenderDescription',
    CJBlockFilter: 'CJBlockFilter',
    CJBlockFilterReferenceDescription: 'CJBlockFilterReferenceDescription',
    CJBlockFilterDescription: 'CJBlockFilterDescription',
    CJBlockPushNotificationSender: 'CJBlockPushNotificationSender',
    CJBlockPushNotificationSenderDescription: 'CJBlockPushNotificationSenderDescription',
    CJBlockPopUpSender: 'CJBlockPopUpSender',
    CJBlockPopUpSenderDescription: 'CJBlockPopUpSenderDescription',
    CJBlockBonusActivation: 'CJBlockBonusActivation',
    CJBlockBonusActivationDescription: 'CJBlockBonusActivationDescription',
    CJBlockCalculateKPISegmentDescription: 'CJBlockCalculateKPISegmentDescription',
    CJBlockCalculateKPIObjectDescription: 'CJBlockCalculateKPIObjectDescription',
    CJBlockCalculateKPIKPIDescription: 'CJBlockCalculateKPIKPIDescription',
    CJBlockCalculateKPIFromFileDescription: 'CJBlockCalculateKPIFromFileDescription',
    CJBlockCalculateKPIKind: 'CJBlockCalculateKPIKind',
    CJBlockCalculateKPIKindDescription: 'CJBlockCalculateKPIKindDescription',
    CJBlockCalculateKPICalculateMethod: 'CJBlockCalculateKPICalculateMethod',
    CJBlockCalculateKPICalculateMethodDescription: 'CJBlockCalculateKPICalculateMethodDescription',
    CJBlockCalculateKPICalculateType: 'CJBlockCalculateKPICalculateType',
    CJBlockReportCellsExceedLimitWarning: 'CJBlockReportCellsExceedLimitWarning',
    CJReportCellsExceedLimitWarning: 'CJReportCellsExceedLimitWarning',
    SaveAsTest: 'SaveAsTest',
    SaveAsLive: 'SaveAsLive',
    PreSaveComment: 'PreSaveComment',
    CountOfSentMessage: 'CountOfSentMessage',
    ID: 'ID',
    TemplateName: 'TemplateName',
    BonusCount: 'BonusCount',
    DateTime: 'DateTime',
    Message: 'Message',
    Small: 'Small',
    Normal: 'Normal',
    Large: 'Large',
    Light: 'Light',
    Dark: 'Dark',
    EmailRecipients: 'EmailRecipients',
    ApplyChanges: 'ApplyChanges',
    DKPIActiveUsers: 'DKPIActiveUsers',
    DKPIActiveUsersToolTip: 'DKPIActiveUsersToolTip',
    DKPIRetentionRate: 'DKPIRetentionRate',
    DKPIRetentionRateToolTip: 'DKPIRetentionRateToolTip',
    DKPIConversionRate: 'DKPIConversionRate',
    DKPIConversionRateToolTip: 'DKPIConversionRateToolTip',
    DKPIMoneyRetentionRate: 'DKPIMoneyRetentionRate',
    DKPIMoneyRetentionRateToolTip: 'DKPIMoneyRetentionRateToolTip',
    DKPIDAUMAU: 'DKPIDAUMAU',
    DKPIDAUMAUToolTip: 'DKPIDAUMAUToolTip',
    Partners: 'Partners',
    BonusName: 'BonusName',
    SegmentName: 'SegmentName',
    MustBeBiggerThan: 'MustBeBiggerThan',
    DKPIDAUWAU: 'DKPIDAUWAU',
    DKPIDAUWAUToolTip: 'DKPIDAUWAUToolTip',
    DKPIARPU: 'DKPIARPU',
    DKPIARPUToolTip: 'DKPIARPUToolTip',
    DKPIReactivationRate: 'DKPIReactivationRate',
    DKPIReactivationRateToolTip: 'DKPIReactivationRateToolTip',
    DKPICLV: 'DKPICLV',
    DKPICLVToolTip: 'DKPICLVToolTip',
    Metrics: 'Metrics',
    WidgetsChooser: 'WidgetsChooser',
    KPIs: 'KPIs',
    Charts: 'Charts',
    CustomerRelationshipMetrics: 'CustomerRelationshipMetrics',
    CampaignsInAction: 'CampaignsInAction',
    Casino: 'Casino',
    Tomorrow: 'Tomorrow',
    KeyPerformanceIndicators: 'KeyPerformanceIndicators',
    ConversionTimeAnalytics: 'ConversionTimeAnalytics',
    ConversionTimeInterval: 'ConversionTimeInterval',
    TimePeriod: 'TimePeriod',
    DailyMetricsForNewCustomers: 'DailyMetricsForNewCustomers',
    DailyActiveCustomersByProductCategories: 'DailyActiveCustomersByProductCategories',
    DailyCommunicationMetrics: 'DailyCommunicationMetrics',
    CustomersSegmentationBasedOnRFMAnalysis: 'CustomersSegmentationBasedOnRFMAnalysis',
    Category: 'Category',
    Empty: 'Empty',
    ReRun: 'ReRun',
    ReRunCampaign: 'ReRunCampaign',
    DoYouWantToReRunCampaign: 'DoYouWantToReRunCampaign',
    PredefinedSegmentInfo: 'PredefinedSegmentInfo',
    DynamicSegmentInfo: 'DynamicSegmentInfo',
    StaticSegmentInfo: 'StaticSegmentInfo',
    CompositeSegmentInfo: 'CompositeSegmentInfo',
    AddFilter: 'AddFilter',
    Filter: 'Filter',
    PrimitiveValue: 'PrimitiveValue',
    Reference: 'Reference',
    FilterCondition: 'FilterCondition',
    Link: 'Link',
    ExcludeFreeBets: 'ExcludeFreeBets',
    ExcludeWageringBets: 'ExcludeWageringBets',
    StepId: 'StepId',
    ClientProfileLiveUpdate: 'ClientProfileLiveUpdate',
    AggregateProperty1: 'AggregateProperty1',
    AggregateProperty2: 'AggregateProperty2',
    AggregateProperty3: 'AggregateProperty3',
    AggregateProperty4: 'AggregateProperty4',
    AggregateProperty5: 'AggregateProperty5',
    AggregatedFilters: 'AggregatedFilters',
    AmountProperty: 'AmountProperty',
    ConsiderLimit: 'ConsiderLimit',
    Note: 'Note',
    TagName: 'TagName',
    Tag: 'Tag',
    SaveModeTitle: 'SaveModeTitle',
    ViewCustomerJourney: 'ViewCustomerJourney',
    ChartOfPlayersCount: 'ChartOfPlayersCount',
    PlayersReceived: 'PlayersReceived',
    PlayersWhoOpenedMail: 'PlayersWhoOpenedMail',
    PlayersWhoClickedTheLink: 'PlayersWhoClickedTheLink',
    Channels: 'Channels',
    Gender: 'Gender',
    BonusType: 'BonusType',
    BonusSum: 'BonusSum',
    AcceptanceType: 'AcceptanceType',
    IsUsed: 'IsUsed',
    ResultType: 'ResultType',
    PrimaryPhone: 'PrimaryPhone',
    Back: 'Back',
    Partner: 'Partner',
    Equal: 'Equal',
    NotEqual: 'NotEqual',
    GreaterThan: 'GreaterThan',
    GreaterThanEqual: 'GreaterThanEqual',
    LessThan: 'LessThan',
    LessThanEqual: 'LessThanEqual',
    IsNull: 'IsNull',
    IsNotNull: 'IsNotNull',
    OnlyFrom: 'OnlyFrom',
    IsEmpty: 'IsEmpty',
    IsNotEmpty: 'IsNotEmpty',
    StartsWith: 'StartsWith',
    EndsWith: 'EndsWith',
    Contains: 'Contains',
    DoesNotContain: 'DoesNotContain',
    Like: 'Like',
    GraphIsCyclicMessage: 'GraphIsCyclicMessage',
    ChartPlayersReceived: 'ChartPlayersReceived',
    ReceivedPlayers: 'ReceivedPlayers',
    Last7Days: 'Last7Days',
    Last30Days: 'Last30Days',
    Block: 'Block',
    Property: 'Property',
    BlockDoesNotExist: 'BlockDoesNotExist',
    BlockCollectedPropertiesNotExist: 'BlockCollectedPropertiesNotExist',
    MatchedBlockPropertyDoesNotVisible: 'MatchedBlockPropertyDoesNotVisible',
    MatchedBlockDoesNotVisible: 'MatchedBlockDoesNotVisible', // with params {{blockName}}
    BlockPropertyDoesNotExist: 'BlockPropertyDoesNotExist',
    SomethingIsWrong: 'SomethingIsWrong',
    ChartPlayersWhoOpenedMail: 'ChartPlayersWhoOpenedMail',
    Mail: 'Mail',
    Games: 'Games',
    CJAutoStartInputInfo: 'CJAutoStartInputInfo',
    CJAutoEndInputInfo: 'CJAutoEndInputInfo',
    ActivityTimeline: 'ActivityTimeline',
    View: 'View',
    NonCustomerInserted: 'Inserted',
    NonCustomerIgnored: 'Ignored',
    NonCustomerLeads: 'Leads',
    NonCustomerUpdated: 'Updated',
    NonCustomerSavedSuccess: 'NonCustomerSavedSuccess',
    FirstOperand: 'FirstOperand',
    SecondOperand: 'SecondOperand',
    SelectedOperator: 'SelectedOperator',
    TimeLineTable: 'TimeLineTable',
    CJActivity: 'CJActivity',
    UsageInSegment: 'UsageInSegment',
    MinimumTwoValidBlocksWithValidConnection: 'MinimumTwoValidBlocksWithValidConnection',
    LeastOneTargetBlock: 'LeastOneTargetBlock',
    AllBlocksMustBeConnected: 'AllBlocksMustBeConnected',
    TargetHasNotInput: 'TargetHasNotInput',
    BlockNameCanNotBeEmpty: 'BlockNameCanNotBeEmpty',
    BlockInvalid: 'BlockInvalid',
    Download: 'Download',
    DownloadAll: 'DownloadAll',
    Appearance: 'Appearance',
    CurrencyForReports: 'CurrencyForReports',
    AllowedPartners: 'AllowedPartners',
    Help: 'Help',
    Value: 'Value',
    FromValue: 'FromValue',
    ToValue: 'ToValue',
    TimeZone: 'TimeZone',
    CJAutoStartEndTimeZoneInfo: 'CJAutoStartEndTimeZoneInfo',
    XLS: 'XLS',
    Customers: 'Customers',
    NonCustomers: 'NonCustomers',
    UsageInReport: 'UsageInReport',
    UsageInCompositeReport: 'UsageInCompositeReport',
    UsageInWorkflowReport: 'UsageInWorkflowReport',
    NotClientYet: 'NotClientYet',
    Set: 'Set',
    SetGoal: 'SetGoal',
    Source: 'Source',
    WorkflowOverall: 'WorkflowOverall',
    SegmentOverall: 'SegmentOverall',
    TemplateOverall: 'TemplateOverall',
    WorkflowBlock: 'WorkflowBlock',
    Created: 'Created',
    Deleted: 'Deleted',
    WorkflowStarted: 'WorkflowStarted',
    WorkflowResume: 'WorkflowResume',
    WorkflowSuspend: 'WorkflowSuspend',
    WorkflowSegmentExecution: 'WorkflowSegmentExecution',
    Information: 'Information',
    CurrencyCode: 'CurrencyCode',
    BonusKPI: 'BonusKPI',
    On: 'On',
    Off: 'Off',
    BonusObjectKind: 'BonusObjectKind',
    ResumeCustomerJourney: 'ResumeCustomerJourney',
    AreYouSureYouWantToResumeCustomerJourney: 'AreYouSureYouWantToResumeCustomerJourney',
    Resume: 'Resume',
    ExecutionId: 'ExecutionId',
    PatternDoesNotMatch: 'PatternDoesNotMatch',
    MustBeBiggerOrEqualThan: 'MustBeBiggerOrEqualThan',
    MustBeSmallerOrEqualThan: 'MustBeSmallerOrEqualThan',
    MaximumNumberDecimalDigit: 'MaximumNumberDecimalDigit',
    CannotBeOnlyMinus: 'CannotBeOnlyMinus',
    ClientList: 'ClientList',
    SaveFilters: 'SaveFilters',
    WriteANameForTheFilter: 'WriteANameForTheFilter',
    FilterName: 'FilterName',
    SavedFilters: 'SavedFilters',
    CantCopy: 'CantCopy',
    HasNotAnySelectedBlock: 'HasNotAnySelectedBlock',
    CantCopyTarget: 'CantCopyTarget',
    SuccessCoped: 'SuccessCoped',
    CantPaste: 'CantPaste',
    CantPasteFromOtherPartner: 'CantPasteFromOtherPartner',
    NoFileChosen: 'NoFileChosen',
    WrongFileFormat: 'WrongFileFormat',
    WrongFileSize: 'WrongFileSize',
    HasUndeletableItemsMessage: 'HasUndeletableItemsMessage',
    Communication: 'Communication',
    ResendAction: 'ResendAction',
    LiveUpdate: 'LiveUpdate',
    ActionList: 'ActionList',
    ClientName: 'ClientName',
    SentDate: 'SentDate',
    PromotionName: 'PromotionName',
    PromotionType: 'PromotionType',
    Send: 'Send',
    AreYouSureYouWantToSendCommunicationToClient: 'AreYouSureYouWantToSendCommunicationToClient',
    YouHaveUnsavedChangesAreYouSureYouWantToLeavePage: 'YouHaveUnsavedChangesAreYouSureYouWantToLeavePage',
    Leave: 'Leave',
    LeavePage: 'LeavePage',
    Stay: 'Stay',
    Configuration: 'Configuration',
    Notifications: 'Notifications',
    BetWin: 'BetWin',
    WithdrawAprove: 'WithdrawAprove',
    WithdrawRejected: 'WithdrawRejected',
    MatchStarted: 'MatchStarted',
    MatchStartedWithPrices: 'MatchStartedWithPrices',
    MatchAboutStart: 'MatchAboutStart',
    MatchAboutStartWithPrices: 'MatchAboutStartWithPrices',
    MatchEnded: 'MatchEnded',
    FootballGoal: 'FootballGoal',
    FootballPenaltyStarted: 'FootballPenaltyStarted',
    FootballCorner: 'FootballCorner',
    FootballYellowCard: 'FootballYellowCard',
    FootballRedCard: 'FootballRedCard',
    Edit: 'Edit',
    EditNotifications: 'EditNotifications',
    FilterGroupWasSavedSuccessfully: 'FilterGroupWasSavedSuccessfully',
    FilterGroupWasDeletedSuccessfully: 'FilterGroupWasDeletedSuccessfully',
    DeleteFilterGroup: 'DeleteFilterGroup',
    AreYouSureYouWantToDeleteFilterGroup: 'AreYouSureYouWantToDeleteFilterGroup', //Are You Sure You Want to Delete {{filterTagName}} Filter Group
    FilterGroupNameAlreadyExistInFiltersGroupList: 'AlreadyExistInFiltersGroupList', //{{filterGroupName}} already exist in filters group list.
    AddFilterGroup: 'AddFilterGroup',
    FilterGroup: 'FilterGroup',
    CustomerJourneyReport: 'CustomerJourneyReport',
    CustomerJourneyReportInfo: 'CustomerJourneyReportInfo',
    CustomerJourneyReportWithJourneyName: 'CustomerJourneyReportWithJourneyName',
    Delivered: 'Delivered',
    Open: 'Open',
    Click: 'Click',
    ClickOnUnsubscribeLink: 'ClickOnUnsubscribeLink',
    TrackedLink: 'TrackedLink',
    ClientsWhoDeliveredMessage: 'ClientsWhoDeliveredMessage',
    ClientsWhoOpenMessage: 'ClientsWhoOpenMessage',
    ClientsWhoClickMessage: 'ClientsWhoClickMessage',
    ClientsWhoUnsubscribeLinkMessage: 'ClientsWhoUnsubscribeLinkMessage',
    ClientsWhoTrackedLinkMessage: 'ClientsWhoTrackedLinkMessage',
    Url: 'Url',
    UrlType: 'UrlType',
    SectionUrl: 'SectionUrl',
    CustomerJourneyKPIs: 'CustomerJourneyKPIs',
    CreateCustomerJourneyVisualization: 'CreateCustomerJourneyVisualization',
    EditCustomerJourneyVisualization: 'EditCustomerJourneyVisualization',
    SaveCustomerJourneyVisualization: 'SaveCustomerJourneyVisualization',
    AddStep: 'AddStep',
    Step: 'Step',
    SubStep: 'SubStep',
    IsHidden: 'IsHidden',
    IsLive: 'IsLive',
    NewStep: 'NewStep',
    StepsSetting: 'StepsSetting',
    Order: 'Order',
    Steps: 'Steps',
    CJVisualizationIsLiveInfo: 'CJVisualizationIsLiveInfo',
    CustomerJourneyAlreadyHasVisualization: 'CustomerJourneyAlreadyHasVisualization',
    NonCustomer: 'NonCustomer',
    SMS: 'SMS',
    Hoory: 'Hoory',
    FastexNotification: 'FastexNotification',
    InternalMessage: 'InternalMessage',
    Social: 'Social',
    Push: 'Push',
    PopUp: 'PopUp',
    DynamicPopUp: 'DynamicPopUp',
    CJInfo: 'CJInfo',
    PredefinedCustomerJourneyInfo: 'PredefinedCustomerJourneyInfo',
    CampaignWasSuccessfullySaved: 'CampaignWasSuccessfullySaved',
    ActionDate: 'ActionDate',
    CheckVisualizationMessage: 'CheckVisualizationMessage',
    WorkflowVisualization: 'WorkflowVisualization',
    CustomerJourneyIsInvalid: 'CustomerJourneyIsInvalid',
    Icons: 'Icons',
    ClassName: 'ClassName',
    Icon: 'Icon',
    CantGetIcons: 'CantGetIcons',
    CJVisualizationWasSuccessfullySaved: 'CJVisualizationWasSuccessfullySaved',
    Activities: 'Activities',
    ConfigurationNotificationsDescription: 'ConfigurationNotificationsDescription',
    ConfigurationProvidersDescription: 'ConfigurationProvidersDescription',
    ConfigurationGeneralDescription: 'ConfigurationGeneralDescription',
    FromAddress: 'FromAddress',
    SSL: 'SSL',
    Host: 'Host',
    SmsProviders: 'SmsProviders',
    UserName: 'UserName',
    AreYouSureYouWantToDeleteProvider: 'AreYouSureYouWantToDeleteProvider',
    AreYouSureYouWantToSetDefaultProvider: 'AreYouSureYouWantToSetDefaultProvider',
    SetDefault: 'SetDefault',
    SetDefaultEmailProvider: 'SetDefaultEmailProvider',
    SetDefaultSmsProvider: 'SetDefaultSmsProvider',
    DeleteEmailProvider: 'DeleteEmailProvider',
    DeleteSmsProvider: 'DeleteSmsProvider',
    FireBaseSettings: 'FireBaseSettings',
    FireBase: 'FireBase',
    OneSignal: 'OneSignal',
    ProviderFireBaseDescription: 'ProviderFireBaseDescription',
    ReportCustomerJourney: 'ReportCustomerJourney',
    SchedulerOnOff: 'SchedulerOnOff',
    CJBlockRemoveByTrip: 'CJBlockRemoveByTrip',
    CJBlockRemoveByTripDescription: 'CJBlockRemoveByTripDescription',
    CJBlockRemoveBySchedule: 'CJBlockRemoveBySchedule',
    CJBlockRemoveByScheduleDescription: 'CJBlockRemoveByScheduleDescription',
    CJBlockJoinToTournament: 'CJBlockJoinToTournament',
    CJBlockJoinToTournamentDescription: 'CJBlockJoinToTournamentDescription',
    BlockName: 'BlockName',
    CustomerJourney: 'CustomerJourney',
    Withdrawal: 'Withdrawal',
    Here: 'Here',
    DynamicVariablesAreInsideTheEditor: 'DynamicVariablesAreInsideTheEditor',
    DynamicVariablesAreNotSupported: 'DynamicVariablesAreNotSupported',
    PushNotification: 'PushNotification',
    PopUpNotification: 'PopUpNotification',
    Telegram: 'Telegram',
    NotificationCampaign: 'NotificationCampaign',
    Manual: 'Manual',
    TestSend: 'TestSend',
    TestUser: 'TestUser',
    TestUserDescription: 'TestUserDescription',
    TestUserSettingsSavedSuccessfully: 'TestUserSettingsSavedSuccessfully',
    TheChosenFileContainsInvalidEmails: 'TheChosenFileContainsInvalidEmails',
    SportBonus: 'SportBonus',
    DepositBonus: 'DepositBonus',
    NoDepositBonus: 'NoDepositBonus',
    CashBonus: 'CashBonus',
    FreeSpin: 'FreeSpin',
    FreeBet: 'FreeBet',
    SkillGames: 'SkillGames',
    CasinoFreeSpin: 'CasinoFreeSpin',
    FantasyTicket: 'FantasyTicket',
    LoyaltyPoint: 'LoyaltyPoint',
    AddSubStep: 'AddSubStep',
    NewSubStep: 'NewSubStep',
    VisualizationSteps: 'VisualizationSteps',
    BetWinDescription: 'BetWinDescription',
    WithdrawAproveDescription: 'WithdrawAproveDescription',
    WithdrawRejectedDescription: 'WithdrawRejectedDescription',
    MatchStartedDescription: 'MatchStartedDescription',
    MatchStartedWithPricesDescription: 'MatchStartedWithPricesDescription',
    MatchAboutStartDescription: 'MatchAboutStartDescription',
    MatchAboutStartWithPricesDescription: 'MatchAboutStartWithPricesDescription',
    MatchEndedDescription: 'MatchEndedDescription',
    FootballGoalDescription: 'FootballGoalDescription',
    FootballPenaltyStartedDescription: 'FootballPenaltyStartedDescription',
    WithdrawalDescription: 'WithdrawalDescription',
    DepositDescription: 'DepositDescription',
    ApplyFilterTag: 'ApplyFilterTag',
    DeleteFilterTag: 'DeleteFilterTag',
    LastYear: 'LastYear',
    LastThirtyMonths: 'LastThirtyMonths',
    FirstDayOfTheCurrentMonth: 'FirstDayOfTheCurrentMonth',
    FirstDayOfThePreviousMonth: 'FirstDayOfThePreviousMonth',
    ViewMode: 'ViewMode',
    ScheduleOffImmediately: 'ScheduleOffImmediately',
    TooltipDownloadTypeInfo: 'TooltipDownloadTypeInfo',
    UnsubscribeLink: 'UnsubscribeLink',
    Awards: 'Awards',
    AwardsDescription: 'AwardsDescription',
    AwardInfo: 'AwardInfo',
    ChooseAwardType: 'ChooseAwardType',
    EditAward: 'EditAward',
    CreateAwardingTitle: 'CreateAwardingTitle',
    EditAwardingTitle: 'EditAwardingTitle',
    SettingsPerCurrency: 'SettingsPerCurrency',
    Min: 'Min',
    Max: 'Max',
    MinCurrencyIsNotValid: 'MinCurrencyIsNotValid',
    MaxCurrencyIsNotValid: 'MaxCurrencyIsNotValid',
    MinMustBeSmallerThanMax: 'MinMustBeSmallerThanMax',
    MaxMustBeBiggerThanMin: 'MaxMustBeBiggerThanMin',
    LoyalityPointsEarned: 'LoyalityPointsEarned',
    CorrectionBonus: 'CorrectionBonus',
    LoyaltyPointsEarned: 'LoyaltyPointsEarned',
    AnalysisDateMustBeBiggerThanCurrentDate: 'AnalysisDateMustBeBiggerThanCurrentDate',
    AnalysisDateMustBeBiggerThanEndDate: 'AnalysisDateMustBeBiggerThanEndDate',
    AnalysisDateMustBeBiggerThanStartDate: 'AnalysisDateMustBeBiggerThanStartDate',
    MaxMustBeBiggerThanZero: 'MaxMustBeBiggerThanZero',
    LP: 'LP',
    ActivatedChannels: 'ActivatedChannels',
    SelectedTemplateName: 'SelectedTemplateName',
    SelectedProviderName: 'SelectedProviderName',
    EnterFirebaseToken: 'EnterFirebaseToken',
    FirebaseToken: 'FirebaseToken',
    AwardType: 'AwardType',
    BonusParametersAreSetupInSpringBackoffice: 'BonusParametersAreSetupInSpringBackoffice',
    SaveNotification: 'SaveNotification',
    NotificationsList: 'NotificationsList',
    NotificationIsLiveInfo: 'NotificationIsLiveInfo',
    SelectedChannels: 'SelectedChannels',
    StartDateMustBeSmallerThenBlockDate: 'StartDateMustBeSmallerThenBlockDate',
    EndDateMustBeBiggerThenBlockDate: 'EndDateMustBeBiggerThenBlockDate',
    TooltipImmediately: 'TooltipImmediately',
    Award: 'Award',
    StartDateMustBeSmallerThanEndDateWithOffset: 'StartDateMustBeSmallerThanEndDateWithOffset',
    EndDateMustBeBiggerThanStartDateWithOffset: 'EndDateMustBeBiggerThanStartDateWithOffset',
    NotificationCampaigns: 'NotificationCampaigns',
    InternalMessages: 'InternalMessages',
    ExportFileFormatCannotBeEmpty: 'ExportFileFormatCannotBeEmpty',
    CreateReportFromSegment: 'CreateReportFromSegment',
    RunImmediatelyInfoMessage: 'RunImmediatelyInfoMessage',
    PlayerId: 'PlayerId',
    Promotion: 'Promotion',
    Enabled: 'Enabled',
    Disabled: 'Disabled',
    BoostBonus: 'BoostBonus',
    ProviderIsNotIntegrated: 'ProviderIsNotIntegrated',
    ApplyExecutionDateAndTime: 'ApplyExecutionDateAndTime',
    ExecutionDate: 'ExecutionDate',
    ExecutionCount: 'ExecutionCount',
    TheDateWillBeChangedByThisExecutionStartAndEndDates: 'TheDateWillBeChangedByThisExecutionStartAndEndDates',
    SaveSegmentChangeIntoReport: 'SaveSegmentChangeIntoReport',
    SaveTheReportAndExecute: 'SaveTheReportAndExecute',
    WaitDataIsProcessed: 'WaitDataIsProcessed',
    DataTooBig: 'DataTooBig',
    PreSaveModalWarningMessage: 'PreSaveModalWarningMessage',
    ChildTemplateDoesNotContainDynamicVariable: 'ChildTemplateDoesNotContainDynamicVariable',
    ChildTemplateDoesNotContainDynamicVariableCascadeSMS: 'ChildTemplateDoesNotContainDynamicVariableCascadeSMS',
    ChildSubjectDoesNotContainDynamicVariable: 'ChildSubjectDoesNotContainDynamicVariable',
    DefaultTemplateDoesNotContainDynamicVariable: 'DefaultTemplateDoesNotContainDynamicVariable',
    DefaultTemplateDoesNotContainDynamicVariableCascadeSMS: 'DefaultTemplateDoesNotContainDynamicVariableCascadeSMS',
    DefaultSubjectDoesNotContainDynamicVariable: 'DefaultSubjectDoesNotContainDynamicVariable',
    Body: 'Body',
    CloneFromDefault: 'CloneFromDefault',
    DeleteSelectedLinesConfirmationText: 'DeleteSelectedLinesConfirmationText',
    DeleteSelectedLines: 'DeleteSelectedLines',
    CommunicationAvailabilityAlways: 'CommunicationAvailabilityAlways',
    CommunicationAvailabilitySameEveryDay: 'CommunicationAvailabilitySameEveryDay',
    CommunicationAvailabilityWeekdaysAndWeekend: 'CommunicationAvailabilityWeekdaysAndWeekend',
    CommunicationAvailabilityDaysOfTheWeek: 'CommunicationAvailabilityDaysOfTheWeek',
    Weekend: 'Weekend',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    CommunicationAvailabilityStartTime: 'CommunicationAvailabilityStartTime',
    CommunicationAvailabilityEndTime: 'CommunicationAvailabilityEndTime',
    StartTimeMustBeSmallerThenEndTimeWithOffset: 'StartTimeMustBeSmallerThenEndTimeWithOffset',
    EndTimeMustBeBiggerThenStartTimeWithOffset: 'EndTimeMustBeBiggerThenStartTimeWithOffset',
    CheckCommunicationAvailabilityScheduleDates: 'CheckCommunicationAvailabilityScheduleDates',
    CommunicationAvailabilityScheduleType: 'CommunicationAvailabilityScheduleType',
    Fail: 'Fail',
    NotStarted: 'NotStarted',
    Weekdays: 'Weekdays',
    CustomerJourneyLimitWarningMessage: 'CustomerJourneyLimitWarningMessage',
    CantPasteOutOfRangeBlocksCounts: 'CantPasteOutOfRangeBlocksCounts',
    Wallet: 'Wallet',
    AvailableBonusTypeAlert: 'AvailableBonusTypeAlert',
    CreateAward: 'CreateAward',
    SiteBuilderType: 'SiteBuilderType',
    EnterValidUrl: 'EnterValidUrl',
    OptionsSelected: 'OptionsSelected',
    SiteBuilderWasCreatedSuccessfully: 'SiteBuilderWasCreatedSuccessfully',
    Website: 'Website',
    SiteUrl: 'SiteUrl',
    CRMIsNotActiveTittle: 'CRMIsNotActiveTittle',
    CRMIsNotActiveDescription: 'CRMIsNotActiveDescription',
    CRMUnderMaintenanceTittle: 'CRMUnderMaintenanceTittle',
    CRMUnderMaintenanceDescription: 'CRMUnderMaintenanceDescription',
    UpcomingMaintenance: 'UpcomingMaintenance',
    UnderMaintenance: 'UnderMaintenance',
    GoToBME: 'GoToBME',
    ExecutedReportParameters: 'ExecutedReportParameters',
    NFTBonus: 'NFTBonus',
    Marketplace: 'Marketplace',
    ShowAll: 'ShowAll',
    ShowAllTooltipInfo: 'ShowAllTooltipInfo',
    SMSParts: 'SMSParts',
    CharactersCountIn: 'CharactersCountIn',
    MaxCharactersCountForSMSPartIsReached: 'MaxCharactersCountForSMSPartIsReached',
    DefaultTemplateContainUnknownDynamicVariable: 'DefaultTemplateContainUnknownDynamicVariable',
    DefaultTemplateContainUnknownDynamicVariableCascadeSMS: 'DefaultTemplateContainUnknownDynamicVariableCascadeSMS',
    DefaultSubjectContainUnknownDynamicVariable: 'DefaultSubjectContainUnknownDynamicVariable',
    TemplateContainDynamicVariableWithUnknownLength: 'TemplateContainDynamicVariableWithUnknownLength',
    TemplateContainDynamicVariableWithUnknownLengthCascadeSMS:
        'TemplateContainDynamicVariableWithUnknownLengthCascadeSMS',
    SubjectContainDynamicVariableWithUnknownLength: 'SubjectContainDynamicVariableWithUnknownLength',
    SetDefaultLanguageWarningMessage: 'SetDefaultLanguageWarningMessage',
    SetDefaultLanguage: 'SetDefaultLanguage',
    Confirm: 'Confirm',
    WarningMessage: 'WarningMessage',
    CompositeReportWasSuccessfullySaved: 'CompositeReportWasSuccessfullySaved',
    CreateCompositeReport: 'CreateCompositeReport',
    CloneCompositeReport: 'CloneCompositeReport',
    EditCompositeReport: 'EditCompositeReport',
    FirstPriority: 'FirstPriority',
    ChooseReport: 'ChooseReport',
    CompositeReports: 'CompositeReports',
    CompositeReport: 'CompositeReport',
    PleaseAddFirstReport: 'PleaseAddFirstReport',
    ReportContainerMustContainMinimumTwoReports: 'ReportContainerMustContainMinimumTwoReports',
    SaveCompositeReport: 'SaveCompositeReport',
    ExecutionsSelfComposite: 'ExecutionsSelfComposite',
    TelegramDescription: 'TelegramDescription',
    HooryDescription: 'HooryDescription',
    DisconnectHoory: 'DisconnectHoory',
    DisconnectHooryDescription: 'DisconnectHooryDescription',
    ReconnectHoory: 'ReconnectHoory',
    ReconnectHooryDescription: 'ReconnectHooryDescription',
    TelegramBotId: 'TelegramBotId',
    TelegramApiKey: 'TelegramApiKey',
    AppKey: 'AppKey',
    AppId: 'AppId',
    TelegramSettings: 'TelegramSettings',
    HoorySettings: 'HoorySettings',
    PushNotificationSettings: 'PushNotificationSettings',
    PushNotificationProviderSuccessfullySetAsDefault: 'PushNotificationProviderSuccessfullySetAsDefault',
    SetDefaultPushNotificationProvider: 'SetDefaultPushNotificationProvider',
    TelegramSettingsSavedSuccessfully: 'TelegramSettingsSavedSuccessfully',
    HoorySettingsSavedSuccessfully: 'HoorySettingsSavedSuccessfully',
    HoorySettingsRemovedSuccessfully: 'HoorySettingsRemovedSuccessfully',
    OneSignalProviderDescription: 'OneSignalProviderDescription',
    OneSignalSettingsSavedSuccessfully: 'OneSignalSettingsSavedSuccessfully',
    CompositeReportUnionTooltipText: 'CompositeReportUnionTooltipText',
    CompositeReportIntersectTooltipText: 'CompositeReportIntersectTooltipText',
    CompositeReportFirstPriorityTooltipText: 'CompositeReportFirstPriorityTooltipText',
    CRMMigrationTittle: 'CRMMigrationTittle',
    CRMMigrationDescription: 'CRMMigrationDescription',
    VisibleTo: 'VisibleTo',
    AllPlayers: 'AllPlayers',
    CJPlayers: 'CJPlayers',
    DeleteSelectedExecutedReports: 'DeleteSelectedExecutedReports',
    DeleteSelectedExecutedReportsQuestion: 'DeleteSelectedExecutedReportsQuestion',
    AllowDeleteSelectedExecutedReports: 'AllowDeleteSelectedExecutedReports',
    NotAllowDeleteSelectedExecutedReports: 'NotAllowDeleteSelectedExecutedReports',
    MustBeLessOrEqualThan: 'MustBeLessOrEqualThan',
    MustBeLessThan: 'MustBeLessThan',
    TotalPercentageCalculation: 'TotalPercentageCalculation',
    TargetPercentageCalculation: 'TargetPercentageCalculation',
    IconSchemeHorizontal: 'IconSchemeHorizontal',
    IconSchemeVertical: 'IconSchemeVertical',
    Reload: 'Reload',
    TemplateId: 'TemplateId',
    SegmentId: 'SegmentId',
    CampaignId: 'CampaignId',
    ReportId: 'ReportId',
    EmailProviderId: 'EmailProviderId',
    SmsProviderId: 'SmsProviderId',
    WorkflowId: 'WorkflowId',
    CreateCustomerJourneyReport: 'CreateCustomerJourneyReport',
    EditCustomerJourneyReport: 'EditCustomerJourneyReport',
    CloneCustomerJourneyReport: 'CloneCustomerJourneyReport',
    SaveCustomerJourneyReport: 'SaveCustomerJourneyReport',
    PleaseSelectCustomerJourney: 'PleaseSelectCustomerJourney',
    PleaseSelectBlock: 'PleaseSelectBlock',
    PleaseAddCustomerJourneyKPIs: 'PleaseAddCustomerJourneyKPIs',
    SpinPriceValue: 'SpinPriceValue',
    SetDefaultFreeSpinExchangeRate: 'SetDefaultFreeSpinExchangeRate',
    EditFreeSpinExchangeRate: 'EditFreeSpinExchangeRate',
    DeleteFreeSpinExchangeRate: 'DeleteFreeSpinExchangeRate',
    AddFreeSpinExchangeRate: 'AddFreeSpinExchangeRate',
    AreYouSureYouWantToSetDefaultFreeSpinExchangeRate: 'AreYouSureYouWantToSetDefaultFreeSpinExchangeRate', //with params:  defaultFreeSpinExchangeRate, currentFreeSpinExchangeRate
    AreYouSureYouWantToDeleteFreeSpinExchangeRate: 'AreYouSureYouWantToDeleteFreeSpinExchangeRate', // with params: currencyName
    OneSpinPrice: 'OneSpinPrice',
    UseSecondaryCurrency: 'UseSecondaryCurrency',
    ReportContainerMustContainUniqueReports: 'ReportContainerMustContainUniqueReports',
    SegmentContainerMustContainUniqueSegments: 'SegmentContainerMustContainUniqueSegments',
    StaticSegmentBonusCurrencyAlertMessage: 'StaticSegmentBonusCurrencyAlertMessage',
    AutoFill: 'AutoFill',
    CurrencyAutoFillInfoAlert: 'CurrencyAutoFillInfoAlert',
    CurrencyAutoFillInfoAlertCreate: 'CurrencyAutoFillInfoAlertCreate',
    AmountMustBeBiggerThanZero: 'AmountMustBeBiggerThanZero',
    CustomerJourneyBlock: 'CustomerJourneyBlock',
    InvalidCustomerJourneyReport: 'InvalidCustomerJourneyReport',
    SaveCMSPopUpNotificationTemplate: 'SaveCMSPopUpNotificationTemplate',
    CMSPopUp: 'CMSPopUp',
    CreateCMSPopUpNotificationTemplate: 'CreateCMSPopUpNotificationTemplate',
    EditCMSPopUpNotificationTemplate: 'EditCMSPopUpNotificationTemplate',
    CloneCMSPopUpNotificationTemplate: 'CloneCMSPopUpNotificationTemplate',
    PleaseSelectPopUp: 'PleaseSelectPopUp',
    CMSPopUpNotificationTemplate: 'CMSPopUpNotificationTemplate',
    CMSPopUpNotification: 'CMSPopUpNotification',
    CreateViberTemplate: 'CreateViberTemplate',
    CloneViberTemplate: 'CloneViberTemplate',
    EditViberTemplate: 'EditViberTemplate',
    SaveViberTemplate: 'SaveViberTemplate',
    ViberContent: 'ViberContent',
    SMSContent: 'SMSContent',
    ButtonText: 'ButtonText',
    ButtonUrl: 'ButtonUrl',
    MediaUrl: 'MediaUrl',
    Text: 'Text',
    Media: 'Media',
    MediaPreview: 'MediaPreview',
    InternalMessageTitle: 'InternalMessageTitle',
    MainSegment: 'MainSegment',
    SubSegment: 'SubSegment',
    SubSegmentUsedInMessage: 'SubSegmentUsedInMessage', //with params {usedInSegments}
    DeleteSubSegmentWarningMessage: 'DeleteSubSegmentWarningMessage',
    OpenCountTooltipInfoMessage: 'OpenCountTooltipInfoMessage',
    Route: 'Route',
    TemplateLanguagesDescription: 'TemplateLanguagesDescription',
    TemplateLanguagesPreSaveInfo: 'TemplateLanguagesPreSaveInfo',
    TemplateLanguagesUsage: 'TemplateLanguagesUsage', // with params templatesCount, clientsCount
    TemplateLanguages: 'TemplateLanguages',
    ArchiveExecutedReport: 'ArchiveExecutedReport',
    UnarchiveExecutedReport: 'UnarchiveExecutedReport',
    ArchiveExecutedReports: 'ArchiveExecutedReports',
    UnarchiveExecutedReports: 'UnarchiveExecutedReports',
    AllowArchiveSelectedExecutedReports: 'AllowArchiveSelectedExecutedReports',
    NotAllowArchiveSelectedExecutedReports: 'NotAllowArchiveSelectedExecutedReports',
    AllowUnarchiveSelectedExecutedReports: 'AllowUnarchiveSelectedExecutedReports',
    NotAllowUnarchiveSelectedExecutedReports: 'NotAllowUnarchiveSelectedExecutedReports',
    ArchiveSelectedExecutedReportsQuestion: 'ArchiveSelectedExecutedReportsQuestion',
    AreYouSureYouWantToArchiveExecutedReport: 'AreYouSureYouWantToArchiveExecutedReport',
    UnarchiveSelectedExecutedReportsQuestion: 'UnarchiveSelectedExecutedReportsQuestion',
    AreYouSureYouWantToUnarchiveExecutedReport: 'AreYouSureYouWantToUnarchiveExecutedReport',
    TemplateContainKPIDynamicVariable: 'TemplateContainKPIDynamicVariable',
    TemplateContainKPIDynamicVariableCascadeSMS: 'TemplateContainKPIDynamicVariableCascadeSMS',
    SubjectContainKPIDynamicVariable: 'SubjectContainKPIDynamicVariable',
    Clients: 'Clients',
    ViewClient: 'ViewClient',
    ShowTotalClientsIncludeRemoved: 'ShowTotalClientsIncludeRemoved',
    SelectedClientsCount: 'SelectedClientsCount',
    TotalClientsCount: 'TotalClientsCount',
    AreYouSureWantToDeleteTag: 'AreYouSureWantToDeleteTag',
    SetTag: 'SetTag',
    RemoveTag: 'RemoveTag',
    SetOnlyForSelection: 'SetOnlyForSelection',
    CreateTag: 'CreateTag',
    DeleteTag: 'DeleteTag',
    Remove: 'Remove',
    Clear: 'Clear',
    ManageClientTagFromFileHint: 'ManageClientTagFromFileHint',
    ManageClientTagHint: 'ManageClientTagHint',
    ClientTagWasSuccessfullyCreated: 'ClientTagWasSuccessfullyCreated',
    ClientTagWasSuccessfullySet: 'ClientTagWasSuccessfullySet',
    ClientTagWasSuccessfullyDeleted: 'ClientTagWasSuccessfullyDeleted',
    ClientTagWasSuccessfullyRemoved: 'ClientTagWasSuccessfullyRemoved',
    ClientTagWasSuccessfullySetOnlyForSelection: 'ClientTagWasSuccessfullySetOnlyForSelection',
    EasyJourney: 'EasyJourney',
    CreateEasyCustomerJourney: 'CreateEasyCustomerJourney',
    EditEasyCustomerJourney: 'EditEasyCustomerJourney',
    CloneEasyCustomerJourney: 'CloneEasyCustomerJourney',
    RegularJourney: 'RegularJourney',
    JourneyPage: 'JourneyPage',
    CreateRealTimePromotion: 'CreateRealTimePromotion',
    ActionCampaign: 'ActionCampaign',
    RealtimeActionCampaign: 'RealtimeActionCampaign',
    CreateActionCampaign: 'CreateActionCampaign',
    EditActionCampaign: 'EditActionCampaign',
    CloneActionCampaign: 'CloneActionCampaign',
    ViewActionCampaign: 'ViewActionCampaign',
    RealtimePromotionsList: 'RealtimePromotionsList',
    SaveRealtimeActionCampaign: 'SaveRealtimeActionCampaign',
    RealtimePromotion: 'RealtimePromotion',
    ChoosePromotionType: 'ChoosePromotionType',
    ReportActionCampaign: 'ReportActionCampaign',
    Starting: 'Starting',
    Updating: 'Updating',
    Resuming: 'Resuming',
    Suspending: 'Suspending',
    Pending: 'Pending',
    FilterByObjectType: 'FilterByObjectType',
    FilterByUser: 'FilterByUser',
    ViewSegment: 'ViewSegment',
    ViewTemplate: 'ViewTemplate',
    ViewCampaign: 'ViewCampaign',
    ViewRealtimePromotion: 'ViewRealtimePromotion',
    ViewReport: 'ViewReport',
    ArchiveReport: 'ArchiveReport',
    UnarchiveReport: 'UnarchiveReport',
    ArchiveSelectedReportsQuestion: 'ArchiveSelectedReportsQuestion', // with params {reportName}
    AreYouSureYouWantToArchiveReport: 'AreYouSureYouWantToArchiveReport',
    AllowArchiveSelectedReports: 'AllowArchiveSelectedReports',
    NotAllowArchiveSelectedReports: 'NotAllowArchiveSelectedReports',
    AllowUnarchiveSelectedReports: 'AllowUnarchiveSelectedReports',
    NotAllowUnarchiveSelectedReports: 'NotAllowUnarchiveSelectedReports',
    ManageTagFromFile: 'ManageTagFromFile',
    ManageTag: 'ManageTag',
    TooltipManageTagClientsUploadFile: 'TooltipManageTagClientsUploadFile',
    TooltipManageTagActions: 'TooltipManageTagActions',
    NewTagName: 'NewTagName',
    NoFileUploaded: 'NoFileUploaded',
    CampaignSchedulerOffInfoMessage: 'CampaignSchedulerOffInfoMessage',
    CJTargetSchedulerOffInfoMessage: 'CJTargetSchedulerOffInfoMessage',
    CJConditionSchedulerOffInfoMessage: 'CJConditionSchedulerOffInfoMessage',
    CJActionSchedulerOffInfoMessage: 'CJActionSchedulerOffInfoMessage',
    HtmlCodeEditor: 'HtmlCodeEditor',
    TextEditor: 'TextEditor',
    SwitchToTextEditorMode: 'SwitchToTextEditorMode',
    Switch: 'Switch',
    AreYouSureYouWantToSwitchEditorMode: 'AreYouSureYouWantToSwitchEditorMode',
    AreYouSureYouWantToSwitchToEditorModeWithClear: 'AreYouSureYouWantToSwitchToEditorModeWithClear',
    AreYouSureYouWantToLeaveEditorModeNotRecoverable: 'AreYouSureYouWantToLeaveEditorModeNotRecoverable',
    ImageGalleryModalTitle: 'ImageGalleryModalTitle',
    BlockEditor: 'BlockEditor',
    BlockEditorLegacy: 'BlockEditorLegacy',
    SwitchToBlockEditorMode: 'SwitchToBlockEditorMode',
    SwitchEditorModeTitle: 'SwitchEditorModeTitle',
    InvalidUnitPairCombination: 'InvalidUnitPairCombination',
    AccountID: 'AccountID',
    UserID: 'UserID',
    ProjectId: 'ProjectId',
    PrivateKeyId: 'PrivateKeyId',
    FirebaseSettingsClearedSuccessfully: 'FirebaseSettingsClearedSuccessfully',
    FirebaseSettingsUploadedSuccessfully: 'FirebaseSettingsUploadedSuccessfully',
    FirebaseSettingsFileIsInvalid: 'FirebaseSettingsFileIsInvalid',
    FirebaseDescription: 'FirebaseDescription',
    ClearSettings: 'ClearSettings',
    UploadSettings: 'UploadSettings',
    ClearFirebaseSettingsDescription: 'ClearFirebaseSettingsDescription',
    UploadFirebaseSettingsDescription: 'UploadFirebaseSettingsDescription',
    Expiry: 'Expiry',
    SyncWithPartnerCurrency: 'SyncWithPartnerCurrency',
    InCorrectDuration: 'InCorrectDuration',
    Duration: 'Duration',
    Hours: 'Hours',
    DeactivatedUser: 'DeactivatedUser',
    RemoveUnavailableSelection: 'RemoveUnavailableSelection',
    FrenchFreeBet: 'FrenchFreeBet',
    PrimaryWallet: 'PrimaryWallet',
    TradingPlan: 'TradingPlan',
    SelectExpirationDate: 'SelectExpirationDate',
    ExpirationDate: 'ExpirationDate',
    ExpirationType: 'ExpirationType',
    FromPayoutDate: 'FromPayoutDate',
    ToGivenDate: 'ToGivenDate',
    ExpirationTypeCannotBeEmpty: 'ExpirationTypeCannotBeEmpty',
    ExpirationDateIsInvalid: 'ExpirationDateIsInvalid',
    ExpirationPeriodIsInvalid: 'ExpirationPeriodIsInvalid',
    SelectPartner: 'SelectPartner',
    PartnerActiveTooltipText: 'PartnerActiveTooltipText',
    PartnerNotActiveTooltipText: 'PartnerNotActiveTooltipText',
    IdCopied: 'IdCopied',
    LoyaltyBenefit: 'LoyaltyBenefit',
    BonusWalletInfo: 'BonusWalletInfo',
    PromotionTimeline: 'PromotionTimeline',
    InvalidTime: 'InvalidTime',
    HomePage: 'HomePage',
    Timeline: 'Timeline',
};

export { labels };

const l = new Proxy(
    { ...administrationLabels, ...labels },
    {
        get(target, name) {
            return name in target ? target[name] : name;
        },
    },
);

export default l;
