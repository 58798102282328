import React from 'react';
// Import UI Components
import Icon from '@geneui/components/Icon';
// Import Constants
import { l, CreatePopoverViews, PERMISSIONS, PARTNER_SETTINGS } from 'constants/common';
import { RoutesList } from 'routes';

const {
    HOMEPAGE,
    DASHBOARD,
    CAMPAIGN_CREATE,
    REALTIME_JOURNEY_CREATE,
    REPORT_CREATE,
    TEMPLATE_CREATE_EMAIL,
    TEMPLATE_CREATE_SMS,
    TEMPLATE_CREATE_HOORY,
    TEMPLATE_CREATE_FASTEX_NOTIFICATION,
    TEMPLATE_CREATE_INTERNAL_MESSAGE,
    TEMPLATE_CREATE_SOCIAL_TELEGRAM,
    TEMPLATE_CREATE_SOCIAL_VIBER,
    TEMPLATE_CREATE_PUSH_NOTIFICATION,
    TEMPLATE_CREATE_POP_UP_NOTIFICATION,
    TEMPLATE_CREATE_DYNAMIC_POP_UP: TEMPLATE_CREATE_DYNAMIC_POP_UP_NOTIFICATION,
    TEMPLATE_CREATE_CMS_POP_UP_NOTIFICATION,
    SEGMENT_DYNAMIC_CREATE,
    SEGMENT_STATIC_CREATE,
    SEGMENT_NON_CUSTOMER_CREATE,
    SEGMENT_COMPOSITE_CREATE,
    SEGMENT_LIST,
    TEMPLATE_LIST,
    CAMPAIGN_LIST,
    REALTIME_PROMOTIONS_LIST,
    REPORT_LIST,
    COMMUNICATION,
    CONFIGURATION,
    REPORT_COMPOSITE_CREATE,
    // ANALYTICS,
    // SETTINGS,
    ADMINISTRATION,
    REPORT_CUSTOMER_JOURNEY_CREATE,
    CLIENT_LIST,
    REALTIME_ACTION_CREATE,
    PROMOTION_TIMELINE,
} = RoutesList;

const NavigationList = [
    {
        routeModel: HOMEPAGE,
        icon: <Icon type="bc-icon-apps" />,
        permission: PERMISSIONS.ViewCampaign,
    },
    {
        routeModel: DASHBOARD,
        icon: <Icon type="bc-icon-dashboard" />,
        permission: PERMISSIONS.ViewCampaign,
    },
    {
        routeModel: SEGMENT_LIST,
        icon: <Icon type="bc-icon-segment" />,
        permission: PERMISSIONS.ViewSegment,
    },
    {
        routeModel: TEMPLATE_LIST,
        icon: <Icon type="bc-icon-template" />,
        permission: PERMISSIONS.ViewTemplate,
    },
    {
        routeModel: CAMPAIGN_LIST,
        icon: <Icon type="bc-icon-campaign" />,
        permission: PERMISSIONS.ViewCampaign,
    },
    {
        routeModel: REALTIME_PROMOTIONS_LIST,
        icon: <Icon type="bc-icon-customer-journey" />,
        permission: PERMISSIONS.ViewWorkflow,
    },
    {
        routeModel: REPORT_LIST,
        icon: <Icon type="bc-icon-reports" />,
        permission: [PERMISSIONS.ViewCustomReport, PERMISSIONS.ViewAddHocReport],
    },
    {
        routeModel: CLIENT_LIST,
        icon: <Icon type="bc-icon-indicate-users" />,
        permission: PERMISSIONS.ViewCampaign,
    },
    {
        routeModel: COMMUNICATION,
        icon: <Icon type="bc-icon-user-management" />,
        permission: PERMISSIONS.ViewCampaign,
    },
    {
        routeModel: CONFIGURATION,
        icon: <Icon type="bc-icon-settings-bars" />,
        permission: PERMISSIONS.ViewCampaign,
    },
    {
        routeModel: ADMINISTRATION,
        icon: <Icon type="bc-icon-auto-generate-48" />,
        permission: [PERMISSIONS.CRMAdmin, PERMISSIONS.CRMManager],
    },
    {
        routeModel: PROMOTION_TIMELINE,
        icon: <Icon type="bc-icon-timeline" />,
        permission: [PERMISSIONS.CRMAdmin, PERMISSIONS.CRMManager],
    },

    // TODO: pages will be implemented in future sprints there are problems related backend and UX
    // {
    //     routeModel: ANALYTICS,
    //     icon: <Icon type="bc-icon-analytic-data-rmt-36" />,
    //     permission: PERMISSIONS.SuperUser,
    // },
    // {
    //     routeModel: SETTINGS,
    //     icon: <Icon type="bc-icon-settings-bars" />,
    //     permission: PERMISSIONS.SuperUser,
    // },
];

const ProductsList = {
    mainProductsGroup: [
        {
            name: 'Affiliates',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'Affiliates',
            url: '',
        },
        {
            name: 'Agent System',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'AgentSystem',
            url: '',
        },
        {
            name: 'Umbrella',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'Umbrella',
            url: '',
        },
        {
            name: 'Skill Games',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'SkillGames',
            url: '',
        },
        {
            name: 'Scouting',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'Scouting',
            url: '',
        },
        {
            name: 'CMS',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'CMS',
            url: '',
        },
        {
            name: 'Agent',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'Agent',
            url: '',
        },
        {
            name: 'Fantasy Sport',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'FantasySport',
            url: '',
        },
    ],
    secondaryProductsGroup: [
        {
            name: 'Promo Tools',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'PromoTools',
            url: '',
        },
        {
            name: 'Slot Builder',
            iconClass: 'bc-icon-products-48',
            displayNameKey: 'SlotBuilder',
            url: '',
        },
    ],
};

const UserProfilePopoverViews = {
    mainView: 'mainView',
    languageView: 'languageView',
};

const CreateEntitiesList = {
    mainViewChildren: [
        {
            iconClass: 'bc-icon-segment',
            displayNameKey: l.Segment,
            hasChildren: true,
            contentViewKey: CreatePopoverViews.segmentView,
            permission: PERMISSIONS.CreateSegment,
        },
        {
            iconClass: 'bc-icon-template',
            displayNameKey: l.Template,
            hasChildren: true,
            contentViewKey: CreatePopoverViews.templateView,
            permission: [PERMISSIONS.CreateTemplate, PERMISSIONS.ViewTemplate],
        },
        {
            iconClass: 'bc-icon-campaign',
            displayNameKey: l.Campaign,
            hasChildren: false,
            routeModel: CAMPAIGN_CREATE,
            permission: PERMISSIONS.CreateCampaign,
        },
        {
            iconClass: 'bc-icon-customer-journey',
            displayNameKey: l.RealtimePromotion,
            hasChildren: true,
            contentViewKey: CreatePopoverViews.customerJourneyView,
            permission: PERMISSIONS.CreateWorkflow,
        },
        {
            iconClass: 'bc-icon-reports',
            displayNameKey: l.Report,
            hasChildren: true,
            contentViewKey: CreatePopoverViews.reportView,
            permission: PERMISSIONS.CreateAddHocReport,
        },
    ],
    templateViewChildren: {
        mainGroup: [
            {
                routeModel: TEMPLATE_CREATE_EMAIL,
                iconClass: 'bc-icon-crm-email',
                displayNameKey: l.Email,
            },
            {
                routeModel: TEMPLATE_CREATE_SMS,
                iconClass: 'bc-icon-message-closed',
                displayNameKey: l.SMS,
            },
            {
                routeModel: TEMPLATE_CREATE_INTERNAL_MESSAGE,
                iconClass: 'bc-icon-sms',
                displayNameKey: l.InternalMessage,
                partnerSetting: PARTNER_SETTINGS.IsAllowInternalMessage,
            },
            {
                iconClass: 'bc-icon-social-48',
                displayNameKey: l.Social,
                hasChildren: true,
                contentViewKey: CreatePopoverViews.socialTemplateView,
            },
            {
                routeModel: TEMPLATE_CREATE_PUSH_NOTIFICATION,
                iconClass: 'bc-icon-bell-outline',
                displayNameKey: l.Push,
                partnerSetting: PARTNER_SETTINGS.IsAllowPushNotification,
            },
            {
                routeModel: TEMPLATE_CREATE_POP_UP_NOTIFICATION,
                iconClass: 'bc-icon-pop-ups',
                displayNameKey: l.PopUp,
                partnerSetting: PARTNER_SETTINGS.IsAllowPopUp,
            },
            {
                routeModel: TEMPLATE_CREATE_DYNAMIC_POP_UP_NOTIFICATION,
                iconClass: 'bc-icon-pop-ups',
                displayNameKey: l.DynamicPopUp,
                partnerSetting: PARTNER_SETTINGS.IsAllowDynamicPopUp,
            },
            {
                routeModel: TEMPLATE_CREATE_CMS_POP_UP_NOTIFICATION,
                iconClass: 'bc-icon-pop-ups',
                displayNameKey: l.CMSPopUp,
                partnerSetting: PARTNER_SETTINGS.IsAllowCMSPopUp,
            },
            {
                routeModel: TEMPLATE_CREATE_HOORY,
                iconClass: 'bc-icon-hoory',
                displayNameKey: l.Hoory,
                partnerSetting: PARTNER_SETTINGS.IsAllowHooryMessage,
            },
            {
                routeModel: TEMPLATE_CREATE_FASTEX_NOTIFICATION,
                iconClass: 'bc-icon-publish-48',
                displayNameKey: l.FastexNotification,
                partnerSetting: PARTNER_SETTINGS.IsAllowFastexNotification,
            },
        ],
        predefinedGroup: {
            displayNameKey: l.PredefinedTemplate,
            contentViewKey: CreatePopoverViews.predefinedTemplateView,
        },
    },
    segmentViewChildren: {
        mainGroup: [
            {
                iconClass: 'bc-icon-dynamic',
                displayNameKey: l.Dynamic,
                routeModel: SEGMENT_DYNAMIC_CREATE,
                permission: PERMISSIONS.CreateSegment,
            },
            {
                iconClass: 'bc-icon-static',
                displayNameKey: l.Static,
                routeModel: SEGMENT_STATIC_CREATE,
                permission: PERMISSIONS.CreateSegment,
            },
            {
                iconClass: 'bc-icon-composite',
                displayNameKey: l.Composite,
                routeModel: SEGMENT_COMPOSITE_CREATE,
                permission: PERMISSIONS.CreateSegment,
            },
            {
                iconClass: 'bc-icon-non-customer',
                displayNameKey: l.NonCustomer,
                routeModel: SEGMENT_NON_CUSTOMER_CREATE,
                permission: PERMISSIONS.ManageNonCustomer,
            },
        ],
        predefinedGroup: {
            displayNameKey: l.PredefinedSegment,
            contentViewKey: CreatePopoverViews.predefinedSegmentView,
        },
    },
    reportViewChildren: {
        mainGroup: [
            {
                iconClass: 'bc-icon-reports',
                displayNameKey: l.Report,
                routeModel: REPORT_CREATE,
                permission: PERMISSIONS.CreateAddHocReport,
            },
            {
                iconClass: 'bc-icon-composite-report',
                displayNameKey: l.CompositeReport,
                routeModel: REPORT_COMPOSITE_CREATE,
                permission: PERMISSIONS.CreateAddHocReport,
            },
            {
                iconClass: 'bc-icon-cj-report',
                displayNameKey: l.CustomerJourneyReport,
                routeModel: REPORT_CUSTOMER_JOURNEY_CREATE,
                mandatoryPermissions: [PERMISSIONS.CreateAddHocReport, PERMISSIONS.ManageWorkflow],
            },
        ],
    },
    customerJourneyViewChildren: {
        mainGroup: [
            {
                iconClass: 'bc-icon-customer-journey',
                displayNameKey: l.Journey,
                routeModel: REALTIME_JOURNEY_CREATE,
                permission: PERMISSIONS.CreateWorkflow,
            },
            {
                iconClass: 'bc-icon-action-compaign',
                displayNameKey: l.RealtimeActionCampaign,
                routeModel: REALTIME_ACTION_CREATE,
                permission: PERMISSIONS.CreateWorkflow,
            },
        ],
        predefinedGroup: {
            displayNameKey: l.PredefinedCustomerJourney,
            contentViewKey: CreatePopoverViews.predefinedCustomerJourneyView,
        },
    },
    awardsViewChildren: {
        mainGroup: [
            {
                iconClass: 'bc-icon-loyalty-point',
                displayNameKey: l.LoyaltyPoint,
                key: 12,
                permissions: [PERMISSIONS.MakeCorrection],
            },
            {
                iconClass: 'bc-icon-send-bonus-block',
                displayNameKey: l.CashBonus,
                key: 13,
                permissions: [PERMISSIONS.MakeCorrection],
            },
            {
                iconClass: 'bc-icon-bonus-complete-block',
                displayNameKey: l.CorrectionBonus,
                key: 4,
                permissions: [PERMISSIONS.MakeCorrection],
            },
            {
                iconClass: 'bc-icon-bonus-claim-block',
                displayNameKey: l.NFTBonus,
                key: 14,
                partnerSetting: PARTNER_SETTINGS.IsAllowNftBonus,
                permissions: [PERMISSIONS.MakeCorrection],
            },
            {
                iconClass: 'bc-icon-crm-free-bet',
                displayNameKey: l.FrenchFreeBet,
                key: 15,
                permissions: [PERMISSIONS.ManageFreeBetBalance],
            },
        ],
    },
    socialTemplateViewChildren: {
        mainGroup: [
            {
                routeModel: TEMPLATE_CREATE_SOCIAL_TELEGRAM,
                iconClass: 'bc-icon-send-telegram-message-block',
                displayNameKey: l.Telegram,
            },
            {
                routeModel: TEMPLATE_CREATE_SOCIAL_VIBER,
                iconClass: 'bc-icon-viber',
                displayNameKey: l.Viber,
                partnerSetting: PARTNER_SETTINGS.IsAllowViberNotification,
            },
        ],
    },
};

const PredefinedListMenuRequestModel = {
    segments: {
        Filters: [
            {
                Name: 'Name',
                Comparision: 2,
                Values: [''],
            },
            {
                Name: 'DefinitionType',
                Comparision: 0,
                Values: [1],
            },
        ],
        Paging: { PageSize: 1000, PageNumber: 1 },
        Sorting: { Name: 'CreatedDate', Direction: 'desc' },
    },
    templates: {
        Filters: [
            {
                Name: 'Name',
                Comparision: 2,
                Values: [''],
            },
            {
                Name: 'ParentTemplateId',
                Comparision: 0,
                Values: [null],
            },
            {
                Name: 'DefinitionType',
                Comparision: 0,
                Values: [1],
            },
        ],
        Paging: { PageSize: 1000, PageNumber: 1 },
        Sorting: { Name: 'CreatedDate', Direction: 'desc' },
    },
    journeys: {
        Filters: [
            {
                Name: 'Name',
                Comparision: 2,
                Values: [''],
            },
            {
                Name: 'DefinitionType',
                Comparision: 0,
                Values: [1],
            },
        ],
        Paging: { PageSize: 1000, PageNumber: 1 },
        Sorting: { Name: 'CreatedDate', Direction: 'desc' },
    },
};

export { NavigationList, ProductsList, UserProfilePopoverViews, CreateEntitiesList, PredefinedListMenuRequestModel };
